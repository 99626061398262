// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bisko-app__analytics .domains-table[data-v-19c9ba64] {
  min-height: 340px !important;
}
.bisko-app__analytics .domains-table .vgt-left-align[data-v-19c9ba64],
.bisko-app__analytics .domains-table .vgt-right-align[data-v-19c9ba64] {
  padding: 13px 12px;
}
.bisko-app__analytics .domains-table table tbody tr[data-v-19c9ba64] {
  border-bottom: unset;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data.column.field === "domain"
    ? _c("span", { staticClass: "domains-table__img-container" }, [
        _c("div", { staticClass: "domains-table__img-container_content" }, [
          _c("img", {
            attrs: { src: _vm.getDomainFavicon(_vm.data.row.domain) },
          }),
        ]),
        _c("span", [
          _c(
            "a",
            {
              attrs: {
                href: `https://${_vm.data.row.domain}`,
                target: "_blank",
              },
            },
            [_vm._v(_vm._s(_vm.data.row.domain))]
          ),
        ]),
      ])
    : _vm.data.column.field === "url"
    ? _c("span", { staticClass: "domains-table__img-container" }, [
        _c("div", { staticClass: "domains-table__img-container_content" }, [
          _c("img", { attrs: { src: _vm.getDomainFavicon(_vm.data.row.url) } }),
        ]),
        _c(
          "span",
          { attrs: { id: `realtime__url-${_vm.data.row.originalIndex}` } },
          [
            _c(
              "a",
              { attrs: { href: `${_vm.data.row.url}`, target: "_blank" } },
              [
                _vm._v(
                  " " + _vm._s(_vm.updateCleanUrlFormat(_vm.data.row.url)) + " "
                ),
              ]
            ),
            _c(
              "b-tooltip",
              {
                attrs: {
                  target: `realtime__url-${_vm.data.row.originalIndex}`,
                },
              },
              [_vm._v(_vm._s(_vm.data.row.url))]
            ),
          ],
          1
        ),
      ])
    : _vm.data.column.field === "source"
    ? _c("span", { staticClass: "domains-table__img-container" }, [
        _c("div", { staticClass: "domains-table__img-container_content" }, [
          _c("img", {
            attrs: { src: _vm.getDomainFavicon(_vm.data.row.source) },
          }),
        ]),
        _c("span", [_vm._v(_vm._s(_vm.data.row.source))]),
      ])
    : _vm.data.column.field === "browser"
    ? _c("span", { staticClass: "domains-table__img-container" }, [
        _c("div", { staticClass: "domains-table__img-container_content" }, [
          _c("img", { attrs: { src: _vm.data.row.icon } }),
        ]),
        _c("span", [_vm._v(_vm._s(_vm.data.row.browser))]),
      ])
    : _vm.data.column.field === "percentage"
    ? _c("span", [_c("span", [_vm._v(_vm._s(_vm.data.row.percentage) + "%")])])
    : _vm.data.column.field === "activeUsers"
    ? _c("span", [
        _c(
          "span",
          {
            staticStyle: { display: "flex", "justify-content": "space-around" },
          },
          [
            _vm._v(_vm._s(_vm.data.row.activeUsers.toLocaleString()) + " "),
            _c("GjIcon", {
              staticStyle: { color: "#36b37e" },
              attrs: {
                hidden: _vm.data.row.growth === -1,
                name: "ArrowUpAlt",
                size: "20",
              },
            }),
            _c("GjIcon", {
              staticStyle: { color: "#e34850" },
              attrs: {
                hidden: _vm.data.row.growth === 1,
                name: "ArrowDownAlt",
                size: "20",
              },
            }),
          ],
          1
        ),
      ])
    : _c("span", [
        _vm._v(
          " " +
            _vm._s(
              _vm.data.formattedRow[_vm.data.column.field].toLocaleString()
            ) +
            " "
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import axiosIns from '@/libs/axios'
import { API_BASE_URL } from '@/utils/constants'
import {
  destructJSONObject,
  formatAnalyticsRequest
} from '@nodus/utilities-front'
import deviceSvg from './svgs'

export default {
  state: {
    deviceOptions: {
      series: [],
      chartOptions: {
        chart: {
          type: 'pie',
          width: 700,
          height: 450,
          events: {
            dataPointSelection(_e, _ctx, cfg) {
              const s = document.querySelector(
                `g[rel="${cfg.dataPointIndex + 1}"]`
              )
              s.parentNode.appendChild(s)
            },
            dataPointMouseEnter(_, _ctx, cfg) {
              const d = document.querySelector(
                `g[rel="${cfg.dataPointIndex + 1}"]`
              )
              d.parentNode.appendChild(d)
            }
          }
        },
        title: {
          text: 'Devices',
          align: 'left',
          style: {
            fontSize: '20px',
            fontWeight: '700',
            fontFamily: 'Inter',
            color: '#031C3C'
          }
        },
        legend: {
          fontFamily: 'Inter',
          fontSize: '14px',
          position: 'bottom',
          markers: {
            width: 16,
            height: 18,
            fillColors: ['none', 'none', 'none', 'none', 'none'],
            customHTML: []
          }
        },
        colors: ['#6882a2', '#c0e2f3', '#a4b3cd', '#052D61'],
        labels: [],
        dataLabels: {
          enabed: true,
          formatter: (val) => `${val.toFixed(2)}%`,
          style: {
            fontSize: '20px',
            fontFamily: 'Inter',
            fontWeight: '300',
            colors: ['#031C3C']
          },
          dropShadow: {
            enabled: false
          }
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            customScale: 0.7,
            dataLabels: {
              offset: 75
            }
          }
        },
        tooltip: {
          style: {
            fontSize: '14px',
            fontFamily: 'Inter',
            fontWeight: '300'
          },
          y: {
            formatter: (val) => val.toLocaleString()
          },
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 1,
            opacity: 1,
            color: '#000'
          }
        },
        stroke: {
          show: false
        },
        states: {
          hover: {
            filter: {
              type: 'none'
            }
          },
          active: {
            filter: {
              type: 'none'
            }
          }
        },
        noData: {
          text: 'Loading...',
          style: {
            color: '#052d61',
            fontFamily: 'Inter'
          }
        }
      }
    }
  },
  getters: {
    devices: (state) => state.deviceOptions
  },
  actions: {
    async getDevices(
      { commit },
      { id, propertyIds, dateFrom, dateTo, cancelToken }
    ) {
      const queryParamString = formatAnalyticsRequest(
        propertyIds,
        dateFrom,
        dateTo
      )
      await axiosIns
        .get(
          `${API_BASE_URL}/api/v1/${id}/analytics/devices?${queryParamString}`,
          {
            cancelToken
          }
        )
        .then((response) => {
          commit('SET_DEVICES', destructJSONObject(response, 'devices'))
        })
        .catch(() => {
          commit('SET_DEVICES', [])
        })
    }
  },
  mutations: {
    SET_DEVICES(state, devices) {
      const series = []
      const labels = []
      const svgs = []

      devices.forEach((d) => {
        series.push(+d.deviceUse)
        labels.push(d.deviceType)
        svgs.push(
          () => deviceSvg[d.deviceType.toLowerCase()] || deviceSvg.other
        )
      })

      state.deviceOptions.series = series
      state.deviceOptions.chartOptions.labels = labels
      state.deviceOptions.chartOptions.legend.markers.customHTML = svgs
      state.deviceOptions.chartOptions.noData.text =
        'No data has been added yet...'
    }
  }
}

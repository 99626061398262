// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.svgMap-map-wrapper {
  background: white;
  padding-top: 38%;
}
.svgMap-tooltip {
  box-shadow: 0 4px 20px rgba(5, 45, 97, 0.16);
  position: absolute;
  z-index: 2;
  border-radius: 4px;
  background: #fff;
  border-bottom: 1px solid #fff;
  transform: translate(-50%, -100%);
  pointer-events: none;
}
.svgMap-tooltip .svgMap-tooltip-content-container {
  padding: 10px 20px;
}
.svgMap-tooltip .svgMap-tooltip-content-container .svgMap-tooltip-flag-container {
  text-align: center;
  margin: 0;
}
.svgMap-tooltip .svgMap-tooltip-content-container .svgMap-tooltip-flag-container.svgMap-tooltip-flag-container-emoji {
  font-size: 25px;
  align-self: center;
}
.svgMap-tooltip .svgMap-tooltip-content-container .svgMap-tooltip-flag-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.svgMap-tooltip .svgMap-tooltip-title {
  white-space: nowrap;
  font-size: 14px;
  padding-left: 5px;
  color: #667c99;
  font-weight: 500;
}
.svgMap-tooltip .svgMap-tooltip-content table {
  font-size: 14px;
  color: #667c99;
}
.svgMap-tooltip .svgMap-tooltip-content table tr {
  border-bottom: none;
}
.svgMap-tooltip .svgMap-tooltip-content table tr td:first-child {
  text-align: left;
}
.svgMap-tooltip .svgMap-tooltip-content table td span {
  color: #052d61;
}
.svgMap-tooltip .svgMap-tooltip-pointer:after {
  border: 1px solid #fff;
}
.svgMap-map-controls-wrapper {
  left: auto !important;
  right: 24px;
  bottom: 24px !important;
  box-shadow: none !important;
}
.svgMap-map-controls-wrapper .svgMap-map-controls-zoom {
  flex-direction: row-reverse;
  gap: 4px;
  background: transparent !important;
}
.svgMap-map-controls-wrapper .svgMap-map-controls-zoom .svgMap-control-button {
  width: 34px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #e0e5eb;
  background: #fff;
}
.svgMap-map-controls-wrapper .svgMap-map-controls-zoom .svgMap-control-button::before {
  height: 1px;
  background: #667c99;
  border-radius: 1px;
}
.svgMap-map-controls-wrapper .svgMap-map-controls-zoom .svgMap-control-button::after {
  width: 1px;
  background: #667c99;
  border-radius: 1px;
}
@media (hover: hover) {
.svgMap-map-wrapper .svgMap-country:hover {
    stroke: #1698db;
    stroke-width: 1;
}
}
.svgMap-map-wrapper .svgMap-map-image .svg-pan-zoom_viewport {
  left: 0;
}
.bisko-app__analytics .realtime__dashboard {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 270px;
}
.bisko-app__analytics .realtime__dashboard-section {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 30px;
}
.bisko-app__analytics .realtime__dashboard-section_left, .bisko-app__analytics .realtime__dashboard-section_right {
  width: 100%;
  height: 100%;
  display: flex;
}
.bisko-app__analytics .realtime__dashboard-section_left {
  flex-shrink: 2;
}
.bisko-app__analytics .realtime__dashboard-section__device {
  flex-grow: 1;
  padding: 0 36px;
}
.bisko-app__analytics .realtime__dashboard-section__device:not(:first-child) {
  border-left: 1px solid #e0e5eb;
}
.bisko-app__analytics .realtime__dashboard-section__device .device__header {
  height: 50%;
}
.bisko-app__analytics .realtime__dashboard-section__device .device__header strong {
  color: #031C3C;
  font-size: 16px;
  display: block;
  margin-top: 12px;
}
.bisko-app__analytics .realtime__dashboard-section__device .device__header span {
  display: block;
  font-size: 12px;
  margin-top: 8px;
}
.bisko-app__analytics .realtime__dashboard-section__device .device__percentage-container {
  height: 50%;
  display: flex;
  align-items: flex-end;
}
.bisko-app__analytics .realtime__dashboard-section__device .device__percentage-container strong {
  color: #031C3C;
  font-size: 28px;
  font-weight: 500;
}
.bisko-app__analytics .realtime__dashboard-section__device .device__percentage-container .device__percentage-arrow {
  border-radius: 13px;
  height: 26px;
  width: 26px;
  margin: 0 0 8px 8px;
}
.bisko-app__analytics .realtime__dashboard-section__device .device__percentage-container .device__percentage-arrow.red {
  background: rgba(227, 72, 80, 0.1);
  color: rgb(227, 72, 80);
  animation: indicator-red 1.5s;
}
.bisko-app__analytics .realtime__dashboard-section__device .device__percentage-container .device__percentage-arrow.green {
  background: rgba(54, 179, 126, 0.1);
  color: rgb(54, 179, 126);
  animation: indicator-green 1.5s;
}
.bisko-app__analytics .realtime__dashboard-section__user-count {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.bisko-app__analytics .realtime__dashboard-section__user-count .user-count__content {
  flex-grow: 1;
}
.bisko-app__analytics .realtime__dashboard-section__user-count .user-count__content strong {
  color: #031C3C;
  padding-right: 8px;
  font-size: 16px;
}
.bisko-app__analytics .realtime__dashboard-section__user-count .user-count__content.counter strong {
  color: #031C3C;
  font-weight: 500;
  font-size: 50px;
  line-height: 54px;
  margin-right: 15px;
}
.bisko-app__analytics .realtime__dashboard-section__user-count .user-count__content.percentage {
  display: flex;
  align-items: flex-end;
}
.bisko-app__analytics .realtime__dashboard-section__user-count .user-count__content.percentage span {
  height: 26px;
  border-radius: 13px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 6px;
}
.bisko-app__analytics .realtime__dashboard-section__user-count .user-count__content.percentage.percentage-up span {
  background: rgba(54, 179, 126, 0.1);
  color: rgb(54, 179, 126);
  animation: indicator-green 1.5s;
}
.bisko-app__analytics .realtime__dashboard-section__user-count .user-count__content.percentage.percentage-down span {
  background: rgba(227, 72, 80, 0.1);
  color: rgb(227, 72, 80);
  animation: indicator-red 1.5s;
}
.bisko-app__analytics .realtime__dashboard-section__user-count .user-count__info {
  display: block;
  font-size: 12px;
  margin-top: 8px;
}
@keyframes indicator-red {
0% {
    background: rgba(227, 72, 80, 0.7);
    color: rgb(227, 72, 80);
}
100% {
    background: rgba(227, 72, 80, 0.1);
    color: rgb(227, 72, 80);
}
}
@keyframes indicator-green {
0% {
    background: rgba(54, 179, 126, 0.7);
    color: rgb(54, 179, 126);
}
100% {
    background: rgba(54, 179, 126, 0.1);
    color: rgb(54, 179, 126);
}
}
.bisko-app__analytics .realtime__wrapper {
  min-height: 300px;
}
.bisko-app__analytics .realtime__wrapper.general__wrapper {
  background: #ffffff;
  margin: 24px 0 0;
  display: flex;
  box-shadow: 0 0 2px #e0e5eb;
  border-radius: 6px;
}
.bisko-app__analytics .realtime__wrapper.general__wrapper--padding {
  padding: 15px;
}
.bisko-app__analytics .real-time__container .general__wrapper--box-shadow {
  box-shadow: 0px 8px 24px rgba(17, 37, 74, 0.08);
  border: 1px solid #EDEFF3;
}
.bisko-app__analytics a {
  color: inherit;
}
.bisko-app__analytics a:hover {
  color: inherit;
}
.bisko-app__analytics .top-active__flex-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  min-height: 400px;
}
.bisko-app__analytics .top-active__flex-wrapper .table__wrapper, .bisko-app__analytics .top-active__flex-wrapper .domains-table {
  height: 340px !important;
  overflow: auto;
}
.bisko-app__analytics .top-active__flex-wrapper .table__wrapper .vgt-wrap .vgt-responsive > table > thead, .bisko-app__analytics .top-active__flex-wrapper .domains-table .vgt-wrap .vgt-responsive > table > thead {
  position: sticky;
  top: 0;
}
.bisko-app__analytics .top-active__flex-wrapper .general__wrapper {
  margin: 24px 0 0;
  display: flex;
  border-radius: 6px;
}
.bisko-app__analytics .top-active__flex-wrapper .general__wrapper.general__wrapper-domains {
  padding: 0;
  flex: 1;
}
.bisko-app__analytics .top-active__flex-wrapper.vgt-wrap .vgt-inner-wrap {
  min-height: -moz-fit-content !important;
  min-height: fit-content !important;
}
.bisko-app__analytics .top-active__flex-wrapper.tab-content,
.bisko-app__analytics .top-active__flex-wrapper table {
  margin-bottom: 0 !important;
}
.bisko-app__analytics .top-active__flex-wrapper.domains-table {
  max-height: 340px !important;
  overflow-y: auto;
}
.bisko-app__analytics .top-active__flex-wrapper.vgt-left-align {
  padding: 12px !important;
}
.bisko-app__analytics .pulse-circle {
  pointer-events: none;
}
.bisko-app__analytics .pulse-circle--green circle {
  fill: #36B37E;
  animation: pulse-core 1.5s ease-in-out 3 forwards;
}
.bisko-app__analytics .pulse-circle--green circle:last-child {
  fill: rgba(54, 179, 126, 0.5);
  animation: pulse-radar 1.5s ease-in-out 3 forwards;
}
.bisko-app__analytics .pulse-circle--red circle {
  fill: #E34850;
  animation: pulse-core 1.5s ease-in-out 3 forwards;
}
.bisko-app__analytics .pulse-circle--red circle:last-child {
  fill: rgba(227, 72, 80, 0.5);
  animation: pulse-radar 1.5s ease-in-out 3 forwards;
}
@keyframes pulse-core {
0% {
    opacity: 0;
    transform: scale(0);
}
30% {
    opacity: 1;
    transform: scale(1);
}
60% {
    opacity: 1;
    transform: scale(1.5);
}
100% {
    opacity: 0;
    transform: scale(2);
}
}
@keyframes pulse-radar {
0% {
    transform: scale(1, 1);
    opacity: 0;
}
50% {
    opacity: 1;
}
100% {
    transform: scale(6, 6);
    opacity: 0;
}
}
.bisko-app__analytics #pulse-country-CL {
  transform: translate(590px, 804px);
}
.bisko-app__analytics #pulse-country-US {
  transform: translate(450px, 320px);
}
.bisko-app__analytics #pulse-country-ES {
  transform: translate(960px, 320px);
}
.bisko-app__analytics #pulse-country-NC {
  transform: translate(1890px, 705px);
}
.bisko-app__analytics #pulse-country-SB {
  transform: translate(1870px, 630px);
}
.bisko-app__analytics #pulse-country-JP {
  transform: translate(1705px, 340px);
}
.bisko-app__analytics #pulse-country-ID {
  transform: translate(1625px, 588px);
}
.bisko-app__analytics #pulse-country-CA {
  transform: translate(500px, 191px);
}
.bisko-app__analytics #pulse-country-NO {
  transform: translate(1010px, 185px);
}
.bisko-app__analytics #pulse-country-VN {
  transform: translate(1585px, 490px);
}
.bisko-app__analytics #pulse-country-LA {
  transform: translate(1545px, 447px);
}
@media only screen and (max-width: 991px) {
.bisko-app__analytics .top-active__flex-wrapper {
    flex-direction: column;
}
.bisko-app__analytics .realtime__dashboard {
    flex-wrap: wrap;
}
.bisko-app__analytics .realtime__dashboard-section {
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px 0;
}
.bisko-app__analytics .realtime__dashboard-section_left {
    padding-bottom: 10px !important;
}
.bisko-app__analytics .realtime__dashboard-section__user-count {
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 0 36px;
}
.bisko-app__analytics .realtime__dashboard-section__user-count > div:first-child {
    margin: 5px 0 0 30px;
}
.bisko-app__analytics .realtime__dashboard-section__user-count .user-count__content {
    flex-grow: unset;
}
}
.bisko-app__analytics .real-time__container {
  padding-bottom: 30px;
}
.bisko-app__analytics .overview-map__section {
  width: 100%;
  height: 520px !important;
  position: relative;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

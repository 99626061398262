var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data.column.field === "pageDomain"
    ? _c("span", { staticClass: "domains-table__img-container" }, [
        _c("div", { staticClass: "domains-table__img-container_content" }, [
          _c("img", {
            attrs: { src: _vm.getDomainFavicon(_vm.data.row.pageDomain) },
          }),
        ]),
        _c("span", [
          _c(
            "a",
            {
              attrs: {
                href: `https://${_vm.data.row.pageDomain}`,
                target: "_blank",
              },
            },
            [_vm._v(_vm._s(_vm.data.row.pageDomain))]
          ),
        ]),
      ])
    : _vm.data.column.field === "pageCleanUrl"
    ? _c("span", { staticClass: "domains-table__img-container" }, [
        _c("div", { staticClass: "domains-table__img-container_content" }, [
          _c("img", {
            attrs: { src: _vm.getDomainFavicon(_vm.data.row.pageCleanUrl) },
          }),
        ]),
        _c(
          "span",
          { attrs: { id: `analytics__url-${_vm.data.row.originalIndex}` } },
          [
            _c(
              "a",
              {
                attrs: {
                  href: `${_vm.data.row.pageCleanUrl}`,
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.updateCleanUrlFormat(_vm.data.row.pageCleanUrl)
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "b-tooltip",
              {
                attrs: {
                  target: `analytics__url-${_vm.data.row.originalIndex}`,
                },
              },
              [_vm._v(_vm._s(_vm.data.row.pageCleanUrl))]
            ),
          ],
          1
        ),
      ])
    : _vm.data.column.field === "pageSource"
    ? _c("span", { staticClass: "domains-table__img-container" }, [
        _c("div", { staticClass: "domains-table__img-container_content" }, [
          _c("img", {
            attrs: { src: _vm.getDomainFavicon(_vm.data.row.pageSource) },
          }),
        ]),
        _c("span", [_vm._v(_vm._s(_vm.data.row.pageSource))]),
      ])
    : _vm.data.column.field === "deviceBrowser"
    ? _c("span", { staticClass: "domains-table__img-container" }, [
        _c(
          "div",
          { staticClass: "domains-table__img-container_content" },
          [
            _vm.data.row.icon
              ? _c("img", { attrs: { src: _vm.data.row.icon } })
              : _vm._e(),
            !_vm.data.row.icon
              ? _c("GjIcon", { attrs: { name: "Globe" } })
              : _vm._e(),
          ],
          1
        ),
        _c("span", [_vm._v(_vm._s(_vm.data.row.deviceBrowser))]),
      ])
    : _vm.data.column.field === "percentage"
    ? _c("span", [_c("span", [_vm._v(_vm._s(_vm.data.row.percentage) + "%")])])
    : _c("span", [
        _vm._v(
          " " +
            _vm._s(
              _vm.data.formattedRow[_vm.data.column.field].toLocaleString()
            ) +
            " "
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
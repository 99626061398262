<template>
  <section class="real-time__container">
    <div class="header__wrapper">
      <b-col lg="4" class="col__position-left">
        <MultipleProperties
          id="select-properties"
          :options="allProperties"
          :is-loading="isLoading.properties"
          @update-data="updateApi"
        />
      </b-col>
    </div>

    <!-- GENERAL INFO SECTION -->
    <b-col
      lg="12"
      class="realtime__wrapper general__wrapper general__wrapper--box-shadow general__wrapper--padding"
    >
      <div
        v-if="isLoading.users || isLoading.devices"
        class="loading__container"
      >
        <LoadingBar />
      </div>
      <div v-else class="realtime__dashboard">
        <div
          class="realtime__dashboard-section realtime__dashboard-section_left"
        >
          <div class="realtime__dashboard-section__user-count">
            <div class="user-count__content">
              <strong>{{ $t('analytics.rightNow') }}</strong
              ><b-badge pill variant="primary">{{
                $t('analytics.live')
              }}</b-badge>
              <span class="user-count__info">{{
                $t('analytics.usersOnSite')
              }}</span>
            </div>
            <div class="user-count__content counter">
              <strong>{{ allActiveUsers.toLocaleString() }}</strong>
            </div>
          </div>
        </div>
        <div
          class="realtime__dashboard-section realtime__dashboard-section_right"
        >
          <div class="realtime__dashboard-section__device">
            <div class="device__header">
              <GjIcon name="Desktop" size="28"></GjIcon>
              <strong>{{ $t('analytics.desktop') }}</strong>
              <span>{{ $t('analytics.activeUsers') }}</span>
            </div>
            <div class="device__percentage-container">
              <strong>{{ deviceUsersGrowth('desktop').percentage }}%</strong>
              <GjIcon
                :hidden="!deviceUsersGrowth('desktop').indicator"
                name="ArrowUpAlt"
                size="20"
                class="device__percentage-arrow green"
              ></GjIcon>
              <GjIcon
                :hidden="deviceUsersGrowth('desktop').indicator"
                name="ArrowDownAlt"
                size="20"
                class="device__percentage-arrow red"
              ></GjIcon>
            </div>
          </div>
          <div class="realtime__dashboard-section__device">
            <div class="device__header">
              <GjIcon name="Tablet" size="28"></GjIcon>
              <strong>{{ $t('analytics.tablet') }}</strong>
              <span>{{ $t('analytics.activeUsers') }}</span>
            </div>
            <div class="device__percentage-container">
              <strong>{{ deviceUsersGrowth('tablet').percentage }}%</strong>
              <GjIcon
                :hidden="!deviceUsersGrowth('tablet').indicator"
                name="ArrowUpAlt"
                size="20"
                class="device__percentage-arrow green"
              ></GjIcon>
              <GjIcon
                :hidden="deviceUsersGrowth('tablet').indicator"
                name="ArrowDownAlt"
                size="20"
                class="device__percentage-arrow red"
              ></GjIcon>
            </div>
          </div>
          <div class="realtime__dashboard-section__device">
            <div class="device__header">
              <GjIcon name="Mobile" size="28"></GjIcon>
              <strong>{{ $t('analytics.mobile') }}</strong>
              <span>{{ $t('analytics.activeUsers') }}</span>
            </div>
            <div class="device__percentage-container">
              <strong>{{ deviceUsersGrowth('mobile').percentage }}%</strong>
              <GjIcon
                :hidden="!deviceUsersGrowth('mobile').indicator"
                name="ArrowUpAlt"
                size="20"
                class="device__percentage-arrow green"
              ></GjIcon>
              <GjIcon
                :hidden="deviceUsersGrowth('mobile').indicator"
                name="ArrowDownAlt"
                size="20"
                class="device__percentage-arrow red"
              ></GjIcon>
            </div>
          </div>
        </div>
      </div>
    </b-col>
    <!-- TOP DOMAINS & TOP PAGES -->
    <div class="top-active__flex-wrapper">
      <div class="general__wrapper general__wrapper-domains">
        <TopActiveTable
          :title="'Top Domains'"
          :data="domains"
          :is-loading="isLoading.domains"
        ></TopActiveTable>
      </div>
      <div class="general__wrapper general__wrapper-domains">
        <TopActiveTable
          :title="'Top Active Page'"
          :data="activePages"
          :is-loading="isLoading.pages"
        ></TopActiveTable>
      </div>
    </div>

    <!-- TOP SOURCES & TOP BROWSERS -->
    <div class="top-active__flex-wrapper">
      <div class="general__wrapper general__wrapper-domains">
        <TopActiveTable
          :title="'Top Sources'"
          :data="sources"
          :is-loading="isLoading.sources"
        ></TopActiveTable>
      </div>
      <div class="general__wrapper general__wrapper-domains">
        <TopActiveTable
          :title="'Top Browsers'"
          :data="browsers"
          :is-loading="isLoading.browsers"
        ></TopActiveTable>
      </div>
    </div>

    <!-- REALTIME MAP OVERVIEW -->
    <div class="map__section domains__wrapper general__wrapper--box-shadow">
      <div class="domains__wrapper--padding">
        <h5>{{ $t('analytics.mapOverview') }}</h5>
      </div>
      <div class="overview-map__section">
        <div id="svgMap"></div>
      </div>
    </div>
  </section>
</template>

<script>
import TopActiveTable from '@/components/table/TopActiveTable.vue'
import store from '@/store'
import { GjIcon, LoadingBar, MultipleProperties } from '@nodus/utilities-front'
import { BBadge, BCol } from 'bootstrap-vue'
import SvgMap from 'svgmap'
import {
  computed,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref
} from 'vue'

export default {
  name: 'RealTime',
  components: {
    BCol,
    BBadge,
    TopActiveTable,
    GjIcon,
    MultipleProperties,
    LoadingBar
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const allProperties = computed(() => store.getters.allProperties)
    const allActiveUsers = computed(() => store.getters['realtime/activeUsers'])
    const domains = computed(() => store.getters['realtime/domains'])
    const activePages = computed(() => store.getters['realtime/pages'])
    const sources = computed(() => store.getters['realtime/sources'])
    const browsers = computed(() => store.getters['realtime/browsers'])
    const devices = computed(() => store.getters['realtime/devices'])
    const mapData = computed(() => store.getters['realtime/map'])

    const oldDevices = computed(() => store.getters['realtime/oldDevices'])
    const oldAllActiveUsers = computed(
      () => store.getters['realtime/oldActiveUsers']
    )
    const property = ref('')
    let map

    const isLoading = reactive({
      properties: true,
      users: true,
      devices: true,
      domains: true,
      pages: true,
      sources: true,
      browsers: true,
      map: true
    })

    onMounted(async () => {
      map = new SvgMap(mapData.value)
      store.dispatch('getProperties', { id: organizationId }).finally(() => {
        isLoading.properties = false
      })
      store
        .dispatch('realtime/getActiveUsers', { id: organizationId })
        .finally(() => {
          isLoading.users = false
        })
      store
        .dispatch('realtime/getDevices', { id: organizationId })
        .finally(() => {
          isLoading.devices = false
        })
      store
        .dispatch('realtime/getDomains', { id: organizationId })
        .finally(() => {
          isLoading.domains = false
        })
      store
        .dispatch('realtime/getPages', { id: organizationId })
        .finally(() => {
          isLoading.pages = false
        })
      store
        .dispatch('realtime/getSources', { id: organizationId })
        .finally(() => {
          isLoading.sources = false
        })
      store
        .dispatch('realtime/getBrowsers', { id: organizationId })
        .finally(() => {
          isLoading.browsers = false
        })
      store.dispatch('realtime/getMap', { id: organizationId }).finally(() => {
        isLoading.map = false
        map.applyData(mapData.value.data)
      })
    })

    const updateApi = async (properties) => {
      property.value = properties
      const propertyIds = property.value ? property.value.map((p) => p.id) : []
      store.commit('realtime/SET_REALTIME_UPDATED', false)
      store.dispatch('realtime/getActiveUsers', {
        id: organizationId,
        propertyIds
      })
      store.dispatch('realtime/getDomains', { id: organizationId, propertyIds })
      store.dispatch('realtime/getPages', { id: organizationId, propertyIds })
      store.dispatch('realtime/getSources', { id: organizationId, propertyIds })
      store.dispatch('realtime/getBrowsers', {
        id: organizationId,
        propertyIds
      })
      store.dispatch('realtime/getDevices', { id: organizationId, propertyIds })
      await store.dispatch('realtime/getMap', {
        id: organizationId,
        propertyIds
      })

      store.commit('realtime/SET_REALTIME_UPDATED', true)
      map.applyData(mapData?.value?.data)
    }

    const refreshData = setInterval(() => {
      updateApi(property.value)
    }, 10000)

    const deviceUsersGrowth = (deviceType) => {
      const device = devices.value.find(
        (d) => d.device.toLowerCase() === deviceType
      )
      const oldDevice = oldDevices.value.find(
        (od) => od.device.toLowerCase() === deviceType
      )
      const percentage = device?.percentage || 0
      let indicator = false

      if (device) {
        indicator = oldDevice ? device.percentage >= oldDevice.percentage : true
      }

      return { percentage, indicator }
    }

    const activeUsersGrowth = computed(() => {
      let showPercentage = true
      const indicator = allActiveUsers.value >= oldAllActiveUsers.value
      let growthRatio = allActiveUsers.value / oldAllActiveUsers.value - 1
      growthRatio = Math.round(growthRatio * 10000) / 10000
      let percentage = indicator ? `+${growthRatio * 100}` : growthRatio * 100

      if (oldAllActiveUsers.value === 0) {
        percentage = 0
        showPercentage = allActiveUsers.value === 0
      }

      return { percentage, indicator, showPercentage }
    })

    onBeforeUnmount(() => {
      store.commit('realtime/SET_REALTIME_UPDATED', false)
      clearInterval(refreshData)
      mapData.value.data.values = []
    })

    return {
      allProperties,
      property,
      updateApi,
      allActiveUsers,
      isLoading,
      domains,
      activePages,
      sources,
      browsers,
      activeUsersGrowth,
      deviceUsersGrowth
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/shared/svg-map.scss';
@import '@/assets/scss/views/real-time/index.scss';
.real-time__container {
  padding-bottom: 30px;
}

.overview-map__section {
  width: 100%;
  height: 520px !important;
  position: relative;
}
</style>

import axiosIns from '@/libs/axios'
import { API_GATEWAY_URL } from '@/utils/constants'
import {
  destructJSONObject,
  formatPropertiesQuery
} from '@nodus/utilities-front'
import { assignGrowthIndicators, isValidHttpUrl } from '../utils'

export default {
  state: {
    table: {
      pageLength: 10,
      columns: [
        {
          label: 'Domain',
          field: 'domain',
          sortable: false,
          thClass: 'not-sortable-headers real-time__header'
        },
        {
          label: 'Active Users',
          field: 'activeUsers',
          sortable: false,
          thClass: 'not-sortable-headers'
        }
      ],
      rows: [],
      indicators: [],
      oldRows: [],
      oldIndicators: [],
      searchTerm: ''
    }
  },
  getters: {
    domains: (state) => state.table
  },
  actions: {
    async getDomains({ commit }, { id, propertyIds }) {
      const properties = formatPropertiesQuery(propertyIds)
      const response = await axiosIns.get(
        `${API_GATEWAY_URL}/api/v1/${id}/realtime/domains?${properties}`
      )

      commit('SET_DOMAINS', destructJSONObject(response, 'domains'))
      commit('SET_GROWTH_INDICATOR')
    }
  },
  mutations: {
    SET_DOMAINS(state, domains) {
      state.table.oldRows = state.table.rows
      state.table.rows = domains.filter((domain) =>
        isValidHttpUrl(`https:${domain.domain}`)
      )
    },
    SET_GROWTH_INDICATOR(state) {
      assignGrowthIndicators(state.table, 'domain')
    }
  }
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "real-time__container" },
    [
      _c(
        "div",
        { staticClass: "header__wrapper" },
        [
          _c(
            "b-col",
            { staticClass: "col__position-left", attrs: { lg: "4" } },
            [
              _c("MultipleProperties", {
                attrs: {
                  id: "select-properties",
                  options: _vm.allProperties,
                  "is-loading": _vm.isLoading.properties,
                },
                on: { "update-data": _vm.updateApi },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-col",
        {
          staticClass:
            "realtime__wrapper general__wrapper general__wrapper--box-shadow general__wrapper--padding",
          attrs: { lg: "12" },
        },
        [
          _vm.isLoading.users || _vm.isLoading.devices
            ? _c(
                "div",
                { staticClass: "loading__container" },
                [_c("LoadingBar")],
                1
              )
            : _c("div", { staticClass: "realtime__dashboard" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "realtime__dashboard-section realtime__dashboard-section_left",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "realtime__dashboard-section__user-count",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "user-count__content" },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("analytics.rightNow"))),
                            ]),
                            _c(
                              "b-badge",
                              { attrs: { pill: "", variant: "primary" } },
                              [_vm._v(_vm._s(_vm.$t("analytics.live")))]
                            ),
                            _c("span", { staticClass: "user-count__info" }, [
                              _vm._v(_vm._s(_vm.$t("analytics.usersOnSite"))),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "user-count__content counter" },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.allActiveUsers.toLocaleString())
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "realtime__dashboard-section realtime__dashboard-section_right",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "realtime__dashboard-section__device" },
                      [
                        _c(
                          "div",
                          { staticClass: "device__header" },
                          [
                            _c("GjIcon", {
                              attrs: { name: "Desktop", size: "28" },
                            }),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("analytics.desktop"))),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("analytics.activeUsers"))),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "device__percentage-container" },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.deviceUsersGrowth("desktop").percentage
                                ) + "%"
                              ),
                            ]),
                            _c("GjIcon", {
                              staticClass: "device__percentage-arrow green",
                              attrs: {
                                hidden:
                                  !_vm.deviceUsersGrowth("desktop").indicator,
                                name: "ArrowUpAlt",
                                size: "20",
                              },
                            }),
                            _c("GjIcon", {
                              staticClass: "device__percentage-arrow red",
                              attrs: {
                                hidden:
                                  _vm.deviceUsersGrowth("desktop").indicator,
                                name: "ArrowDownAlt",
                                size: "20",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "realtime__dashboard-section__device" },
                      [
                        _c(
                          "div",
                          { staticClass: "device__header" },
                          [
                            _c("GjIcon", {
                              attrs: { name: "Tablet", size: "28" },
                            }),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("analytics.tablet"))),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("analytics.activeUsers"))),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "device__percentage-container" },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.deviceUsersGrowth("tablet").percentage
                                ) + "%"
                              ),
                            ]),
                            _c("GjIcon", {
                              staticClass: "device__percentage-arrow green",
                              attrs: {
                                hidden:
                                  !_vm.deviceUsersGrowth("tablet").indicator,
                                name: "ArrowUpAlt",
                                size: "20",
                              },
                            }),
                            _c("GjIcon", {
                              staticClass: "device__percentage-arrow red",
                              attrs: {
                                hidden:
                                  _vm.deviceUsersGrowth("tablet").indicator,
                                name: "ArrowDownAlt",
                                size: "20",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "realtime__dashboard-section__device" },
                      [
                        _c(
                          "div",
                          { staticClass: "device__header" },
                          [
                            _c("GjIcon", {
                              attrs: { name: "Mobile", size: "28" },
                            }),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("analytics.mobile"))),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("analytics.activeUsers"))),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "device__percentage-container" },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.deviceUsersGrowth("mobile").percentage
                                ) + "%"
                              ),
                            ]),
                            _c("GjIcon", {
                              staticClass: "device__percentage-arrow green",
                              attrs: {
                                hidden:
                                  !_vm.deviceUsersGrowth("mobile").indicator,
                                name: "ArrowUpAlt",
                                size: "20",
                              },
                            }),
                            _c("GjIcon", {
                              staticClass: "device__percentage-arrow red",
                              attrs: {
                                hidden:
                                  _vm.deviceUsersGrowth("mobile").indicator,
                                name: "ArrowDownAlt",
                                size: "20",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
        ]
      ),
      _c("div", { staticClass: "top-active__flex-wrapper" }, [
        _c(
          "div",
          { staticClass: "general__wrapper general__wrapper-domains" },
          [
            _c("TopActiveTable", {
              attrs: {
                title: "Top Domains",
                data: _vm.domains,
                "is-loading": _vm.isLoading.domains,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "general__wrapper general__wrapper-domains" },
          [
            _c("TopActiveTable", {
              attrs: {
                title: "Top Active Page",
                data: _vm.activePages,
                "is-loading": _vm.isLoading.pages,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "top-active__flex-wrapper" }, [
        _c(
          "div",
          { staticClass: "general__wrapper general__wrapper-domains" },
          [
            _c("TopActiveTable", {
              attrs: {
                title: "Top Sources",
                data: _vm.sources,
                "is-loading": _vm.isLoading.sources,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "general__wrapper general__wrapper-domains" },
          [
            _c("TopActiveTable", {
              attrs: {
                title: "Top Browsers",
                data: _vm.browsers,
                "is-loading": _vm.isLoading.browsers,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass:
            "map__section domains__wrapper general__wrapper--box-shadow",
        },
        [
          _c("div", { staticClass: "domains__wrapper--padding" }, [
            _c("h5", [_vm._v(_vm._s(_vm.$t("analytics.mapOverview")))]),
          ]),
          _vm._m(0),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "overview-map__section" }, [
      _c("div", { attrs: { id: "svgMap" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
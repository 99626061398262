/* eslint-disable no-param-reassign */
import axiosIns from '@/libs/axios'
import { isValidHttpUrl } from '@/store/real-time/utils'
import { API_BASE_URL } from '@/utils/constants'
import {
  destructJSONObject,
  formatAnalyticsDetailedRequest,
  formatAnalyticsRequest
} from '@nodus/utilities-front'
import graph from './graph'

export default {
  modules: { graph },
  state: {
    table: {
      pageLength: 10,
      columns: [
        {
          label: 'Domains',
          field: 'pageDomain',
          width: '55%',
          sortable: false,
          thClass: 'not-sortable-headers custom-analytics__header'
        },
        {
          label: 'Pageviews',
          field: 'pageViews',
          type: 'number',
          width: '15%'
        },
        {
          label: 'Unique Users',
          field: 'uniqueUsers',
          type: 'number',
          width: '15%'
        },
        {
          label: 'Sessions',
          field: 'sessions',
          type: 'number',
          width: '15%'
        }
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: ''
    }
  },
  getters: {
    domains: (state) => state.table,
    detailedDomains: (state) => state.table
  },
  actions: {
    async getDomains(
      { commit },
      { id, propertyIds, dateFrom, dateTo, cancelToken }
    ) {
      const queryParamString = formatAnalyticsRequest(
        propertyIds,
        dateFrom,
        dateTo
      )
      await axiosIns
        .get(
          `${API_BASE_URL}/api/v1/${id}/analytics/domains?${queryParamString}`,
          {
            cancelToken
          }
        )
        .then((response) => {
          commit('SET_DOMAINS', destructJSONObject(response, 'domains'))
        })
        .catch(() => {
          commit('SET_DOMAINS', [])
        })
    },
    async getDetailedDomains(
      { commit, state },
      { id, propertyIds, dateFrom, dateTo }
    ) {
      const queryParamString = formatAnalyticsDetailedRequest(
        state.table.page,
        state.table.perPage,
        propertyIds,
        dateFrom,
        dateTo,
        state.table.orderField,
        state.table.orderType
      )
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/analytics/details/domains?${queryParamString}`
      )
      commit(
        'SET_DOMAINS_DETAILED',
        destructJSONObject(response, 'detailedDomains')
      )
      commit('SET_TOTAL_RECORDS', destructJSONObject(response, 'totalCount'))
    },
    async getDetailedDomainsFile(
      _,
      { id, propertyIds, dateFrom, dateTo, format }
    ) {
      const queryParamString = formatAnalyticsRequest(
        propertyIds,
        dateFrom,
        dateTo
      )
      return axiosIns
        .get(
          `${API_BASE_URL}/api/v1/${id}/analytics/details/domains/file?FileDownloadFormat=${format}&${queryParamString}`,
          { responseType: 'blob' }
        )
        .then((response) => response.data)
    }
  },
  mutations: {
    SET_DOMAINS(state, domains) {
      state.table.rows = domains.filter((domain) =>
        isValidHttpUrl(`https:${domain.pageDomain}`)
      )
    },
    SET_DOMAINS_DETAILED(state, detailedDomains) {
      state.table.rows = detailedDomains.filter((domain) =>
        isValidHttpUrl(`https:${domain.pageDomain}`)
      )
    },
    SET_TOTAL_RECORDS(state, totalRecords) {
      state.table.totalRecords = totalRecords
    }
  }
}

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bisko-app__analytics .general__wrapper {
  background: white;
  margin: 25px 0;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
}
.bisko-app__analytics .general__wrapper--box-shadow {
  box-shadow: 0px 8px 24px rgba(17, 37, 74, 0.08);
  border: 1px solid #edeff3;
}
.bisko-app__analytics .general__wrapper--padding {
  padding: 32px 16px 16px;
}
.bisko-app__analytics .general__wrapper-domains {
  padding: 0;
}
.bisko-app__analytics .general__wrapper-info {
  padding: 0 25px;
  align-self: center;
  border-left: 1px solid #e0e5eb;
}
.bisko-app__analytics .general__wrapper-info:first-child {
  border-left: none;
  padding-left: 12px;
}
.bisko-app__analytics .general__wrapper-info p {
  margin-bottom: 0;
  font-size: 13px;
  margin-top: -10px;
}
.bisko-app__analytics .general__wrapper-info span {
  font-size: 28px;
  font-weight: 500;
  color: #031c3c;
}
.bisko-app__analytics .wrapper__padding {
  padding: 15px;
}
.bisko-app__analytics .general-info__container {
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.bisko-app__analytics .general__wrapper--height {
  min-height: 96px;
}
.bisko-app__analytics .echarts {
  width: 100%;
}
.bisko-app__analytics .pie-and-sources__container {
  display: flex;
  justify-content: space-between;
}
.bisko-app__analytics .pie-and-sources__container > div {
  width: calc(50% - 25px);
}
.bisko-app__analytics .pie-and-sources__container .apex-chart__container {
  width: 100%;
  border-right: 1px solid #eaeaea;
}
.bisko-app__analytics .analytics__pie-chart {
  display: block;
}
.bisko-app__analytics .analytics__pie-chart .apexcharts-pie-series.legend-mouseover-inactive {
  opacity: 0.1;
}
.bisko-app__analytics .analytics__pie-chart .apexcharts-datalabels.legend-mouseover-inactive {
  opacity: 1;
}
.bisko-app__analytics .analytics__pie-chart .apexcharts-pie-area:hover, .bisko-app__analytics .analytics__pie-chart .apexcharts-pie-area[selected=true] {
  transform-origin: center;
  transform: scale(1.05);
  transition: transform 0.25s ease-out;
  filter: brightness(1.15) drop-shadow(1px 1px 10px rgb(62, 62, 62));
}
.bisko-app__analytics .analytics__pie-chart .apexcharts-legend {
  overflow: hidden;
}
.bisko-app__analytics .analytics__pie-chart .apexcharts-legend-marker {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.bisko-app__analytics .analytics__pie-chart .apexcharts-legend-marker svg {
  vertical-align: middle;
}
.bisko-app__analytics .flatpickr-calendar.open {
  z-index: 10;
}
@media only screen and (max-width: 991px) {
.bisko-app__analytics .general-info__container {
    flex-wrap: wrap;
}
.bisko-app__analytics .general-info__container .general__wrapper-info {
    padding: 10px 25px;
    width: 280px;
    border-left: none;
}
.bisko-app__analytics .pie-and-sources__container {
    flex-direction: column;
}
.bisko-app__analytics .pie-and-sources__container > div {
    width: 100% !important;
}
.bisko-app__analytics .pie-and-sources__container > div .apex-chart__container {
    border-right: none;
}
}
@media only screen and (max-width: 768px) {
.bisko-app__analytics .general-info__container .general__wrapper-info {
    width: 200px;
}
}
@media only screen and (max-width: 425px) {
.bisko-app__analytics .realtime__dashboard-section_right {
    flex-wrap: wrap;
}
.bisko-app__analytics .realtime__dashboard-section_right .realtime__dashboard-section__device {
    border-left: none !important;
}
}
.bisko-app__analytics .analytics__detailed-table {
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
}
.bisko-app__analytics .analytics__detailed-table .vgt-wrap .vgt-responsive table tbody tr {
  border: none;
}
.bisko-app__analytics .analytics__detailed-table td.vgt-left-align,
.bisko-app__analytics .analytics__detailed-table td.vgt-right-align {
  padding: 10px 12px !important;
}
.bisko-app__analytics .analytics__detailed-table .tab-content,
.bisko-app__analytics .analytics__detailed-table table {
  margin-bottom: 0px !important;
}
.bisko-app__analytics .analytics__detailed-table .user-roles__pagination {
  bottom: 0px;
  position: relative;
  padding: 8px 0;
  margin: 0;
  width: 100%;
}
.bisko-app__analytics .analytics__detailed-table .vgt-left-align,
.bisko-app__analytics .analytics__detailed-table .vgt-right-align {
  padding: 13px 12px;
}
.bisko-app__analytics .analytics__detailed-table .table-empty-content {
  display: flex;
  justify-content: center;
}
.bisko-app__analytics .analytics__detailed-table--overflow .vgt-wrap .vgt-responsive {
  max-height: 800px;
  overflow-y: auto;
}
.bisko-app__analytics .analytics__detailed-table--overflow .vgt-wrap .vgt-responsive table thead {
  position: sticky;
  top: 0;
}
.bisko-app__analytics .analytics__detailed-chart {
  background: #ffffff;
  border-radius: 5px;
  margin: 20px 0;
  padding: 20px 0 0 20px;
  overflow: hidden;
  box-shadow: 0px 8px 24px rgba(17, 37, 74, 0.08);
  border-radius: 8px;
  border: 1px solid #edeff3;
}
.bisko-app__analytics .analytics__detailed-chart .analytics-chart__yaxis {
  margin-right: 5px;
}
.bisko-app__analytics .analytics__detailed-chart .apexcharts-xaxistooltip {
  background: transparent;
  border: none;
}
.bisko-app__analytics .analytics__detailed-chart .apexcharts-xaxistooltip-text {
  font-family: "Inter", "SFProText" !important;
  font-weight: bold;
  color: #7483a1;
}
.bisko-app__analytics .analytics__detailed-chart .apexcharts-xaxistooltip-text::before, .bisko-app__analytics .analytics__detailed-chart .apexcharts-xaxistooltip-text::after {
  display: none;
}
.bisko-app__analytics .analytics__detailed-chart .apexcharts-xaxistooltip-bottom::before, .bisko-app__analytics .analytics__detailed-chart .apexcharts-xaxistooltip-bottom::after {
  display: none;
}
.bisko-app__analytics .analytics__detailed-chart .apexcharts-tooltip-title {
  display: none;
}
.bisko-app__analytics .analytics__detailed-chart .apexcharts-tooltip-series-group {
  padding-bottom: 0;
}
.bisko-app__analytics .general__wrapper-analytics .domains__wrapper--loading {
  min-height: 600px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

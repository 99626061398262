<template>
  <section>
    <div>
      <div class="header__wrapper">
        <b-col lg="4" class="col__position-left">
          <MultipleProperties
            id="select-properties"
            :options="allProperties"
            :is-loading="isLoading.properties"
            @update-data="updateApi"
          />
        </b-col>
        <b-col class="col__position-right">
          <span class="calendar__range-label"
            >{{ $t('analytics.showDataPer') }}:</span
          >
          <div class="header__wrapper-input calendar__range-input">
            <flat-pickr
              id="select-datepicker"
              ref="flatpickr"
              v-model="date"
              class="form-control date"
              :config="datepickerConfig"
            />
            <span class="calendar-toggle" @click="toggleCalendar()">
              <img src="@/assets/svgs/calendar.svg" alt="calendar" />
            </span>
          </div>
        </b-col>
      </div>
      <b-col
        lg="12"
        class="general__wrapper general__wrapper--box-shadow general__wrapper--padding general__wrapper--height"
      >
        <div
          v-if="!isLoading.general && general.length > 0"
          class="general-info__container"
        >
          <div class="general__wrapper-info">
            <p>{{ $t('analytics.uniqueUsers') }}</p>
            <span>{{ general[0].uniqueUsers.toLocaleString() }}</span>
          </div>
          <div v-if="!isLoading.general" class="general__wrapper-info">
            <p>{{ $t('analytics.pageViews') }}</p>
            <span>{{ general[0].pageViews.toLocaleString() }}</span>
          </div>
          <div v-if="!isLoading.general" class="general__wrapper-info">
            <p>{{ $t('analytics.sessions') }}</p>
            <span>{{ general[0].sessions.toLocaleString() }}</span>
          </div>
          <div v-if="!isLoading.general" class="general__wrapper-info">
            <p>{{ $t('analytics.avgPerUsers') }}</p>
            <span>{{ general[0].averagePageView.toLocaleString() }}</span>
          </div>
        </div>
        <div v-else class="loading__container">
          <LoadingBar classList="medium" />
        </div>
      </b-col>
      <b-col lg="12" class="general__wrapper general__wrapper-domains">
        <OverviewTable
          :title="'Top Domains'"
          :data="domains"
          :type="'domains'"
          :is-loading="isLoading.domains"
        ></OverviewTable>
      </b-col>
      <b-col lg="12" class="general__wrapper general__wrapper-domains">
        <OverviewTable
          title="Top URLs"
          :data="urls"
          :type="'urls'"
          :is-loading="isLoading.urls"
        ></OverviewTable>
      </b-col>
      <div class="map__section domains__wrapper general__wrapper--box-shadow">
        <h5 class="map-title">{{ $t('analytics.mapOverview') }}</h5>
        <div class="overview-map__section">
          <div id="svgMap"></div>
          <div v-if="isLoading.map" class="map-loading__container">
            <LoadingBar classList="large" />
          </div>
        </div>
      </div>
      <div class="pie-and-sources__container">
        <div class="general__wrapper wrapper__padding analytics__pie-chart">
          <vue-apex-charts
            :key="pieKey"
            type="pie"
            :options="devices.chartOptions"
            :series="devices.series"
            height="450"
            class="apex-chart__container"
          />
        </div>
        <b-col
          lg="6"
          md="12"
          sm="12"
          class="general__wrapper general__wrapper-domains"
        >
          <OverviewTable
            :title="'Top Sources'"
            :data="sources"
            :type="'sources'"
            :is-loading="isLoading.sources"
          ></OverviewTable>
        </b-col>
      </div>
      <b-col lg="12" class="general__wrapper general__wrapper-domains">
        <OverviewTable
          :title="'Top Browsers'"
          :data="browsers"
          :type="'browsers'"
          :is-loading="isLoading.browsers"
        ></OverviewTable>
      </b-col>
    </div>
  </section>
</template>

<script>
import OverviewTable from '@/components/table/OverviewTable.vue'
import store from '@/store'
import { LoadingBar, MultipleProperties } from '@nodus/utilities-front'
import axios from 'axios'
import { BCol } from 'bootstrap-vue'
import 'flatpickr/dist/flatpickr.css'
import SvgMap from 'svgmap'
import {
  computed,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref
} from 'vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'Overview',
  components: {
    BCol,
    LoadingBar,
    VueApexCharts,
    // Domain's Component
    OverviewTable,
    flatPickr,
    MultipleProperties
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const flatpickr = ref('')
    const property = ref('')
    const date = ''
    const general = computed(() => store.getters['overview/general'])
    const domains = computed(() => store.getters['overview/domains'])
    const urls = computed(() => store.getters['overview/urls'])
    const sources = computed(() => store.getters['overview/sources'])
    const browsers = computed(() => store.getters['overview/browsers'])
    const devices = computed(() => store.getters['overview/devices'])
    const allProperties = computed(() => store.getters.allProperties)
    const mapData = computed(() => store.getters['overview/map'])
    const pieKey = ref('pie')
    let map
    let dateFrom = ''
    let dateTo = ''
    const isLoading = reactive({
      properties: true,
      general: true,
      domains: true,
      urls: true,
      sources: true,
      browsers: true,
      devices: true,
      map: true
    })

    const request = ref([
      {
        cancel: ''
      }
    ])

    const propertyIds = computed(() =>
      property.value ? property.value.map((p) => p.id) : []
    )

    const getLastWeek = () => {
      const today = new Date()
      today.setDate(today.getDate() - 7)
      const lastWeek = new Date(today)
      dateFrom = `${lastWeek.getFullYear()}-${
        lastWeek.getMonth() + 1
      }-${lastWeek.getDate()}`
      dateTo = `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`
      return `[${dateFrom} to ${dateTo}]`
    }

    const getCancellationToken = () => {
      const { CancelToken } = axios
      return CancelToken.source()
    }

    onMounted(async () => {
      map = new SvgMap(mapData.value)
      getLastWeek()
      const source = getCancellationToken()
      request.value = { cancel: source.cancel }

      store.dispatch('getProperties', { id: organizationId }).finally(() => {
        isLoading.properties = false
      })
      store
        .dispatch('overview/getDevices', {
          id: organizationId,
          dateFrom,
          dateTo,
          cancelToken: source.token
        })
        .finally(() => {
          isLoading.devices = false
          pieKey.value = 'devices'
        })
      store
        .dispatch('overview/getDomains', {
          id: organizationId,
          dateFrom,
          dateTo,
          cancelToken: source.token
        })
        .finally(() => {
          isLoading.domains = false
        })
      store
        .dispatch('overview/getURLs', {
          id: organizationId,
          dateFrom,
          dateTo,
          cancelToken: source.token
        })
        .finally(() => {
          isLoading.urls = false
        })
      store
        .dispatch('overview/getSources', {
          id: organizationId,
          dateFrom,
          dateTo,
          cancelToken: source.token
        })
        .finally(() => {
          isLoading.sources = false
        })
      store
        .dispatch('overview/getBrowsers', {
          id: organizationId,
          dateFrom,
          dateTo,
          cancelToken: source.token
        })
        .finally(() => {
          isLoading.browsers = false
        })
      store
        .dispatch('overview/getGeneral', {
          id: organizationId,
          dateFrom,
          dateTo,
          cancelToken: source.token
        })
        .finally(() => {
          isLoading.general = false
        })

      store
        .dispatch('overview/getMap', {
          id: organizationId,
          dateFrom,
          dateTo,
          cancelToken: source.token
        })
        .finally(() => {
          isLoading.map = false
          map.applyData(mapData?.value?.data)
        })
    })

    const clearOldRequest = () => {
      request.value = null
    }

    const cancel = () => {
      request.value.cancel()
      clearOldRequest()
    }

    const update = async () => {
      devices.value.chartOptions.noData.text = 'Loading...'
      pieKey.value = 'pie'
      const source = getCancellationToken()
      request.value = { cancel: source.cancel }

      Object.keys(isLoading).forEach((key) => {
        if (key === 'properties') return
        isLoading[key] = true
      })

      Promise.all([
        store
          .dispatch('overview/getDevices', {
            id: organizationId,
            dateFrom,
            dateTo,
            propertyIds: propertyIds.value,
            cancelToken: source.token
          })
          .finally(() => {
            isLoading.devices = false
            pieKey.value = 'devices'
          }),
        store
          .dispatch('overview/getDomains', {
            id: organizationId,
            dateFrom,
            dateTo,
            propertyIds: propertyIds.value,
            cancelToken: source.token
          })
          .finally(() => {
            isLoading.domains = false
          }),
        store
          .dispatch('overview/getURLs', {
            id: organizationId,
            dateFrom,
            dateTo,
            propertyIds: propertyIds.value,
            cancelToken: source.token
          })
          .finally(() => {
            isLoading.urls = false
          }),
        store
          .dispatch('overview/getSources', {
            id: organizationId,
            dateFrom,
            dateTo,
            propertyIds: propertyIds.value,
            cancelToken: source.token
          })
          .finally(() => {
            isLoading.sources = false
          }),
        store
          .dispatch('overview/getBrowsers', {
            id: organizationId,
            dateFrom,
            dateTo,
            propertyIds: propertyIds.value,
            cancelToken: source.token
          })
          .finally(() => {
            isLoading.browsers = false
          }),
        store
          .dispatch('overview/getGeneral', {
            id: organizationId,
            dateFrom,
            dateTo,
            propertyIds: propertyIds.value,
            cancelToken: source.token
          })
          .finally(() => {
            isLoading.general = false
          }),
        store
          .dispatch('overview/getMap', {
            id: organizationId,
            dateFrom,
            dateTo,
            propertyIds: propertyIds.value,
            cancelToken: source.token
          })
          .finally(() => {
            isLoading.map = false
            map.applyData(mapData?.value?.data)
          })
      ]).finally(() => {
        clearOldRequest()
      })
    }

    const updateApi = (properties) => {
      property.value = properties
      if (request.value) cancel()
      update(properties)
    }

    const datepickerConfig = {
      mode: 'range',
      defaultDate: getLastWeek(),
      maxDate: new Date().getTime() + 1,
      altInput: true,
      altFormat: 'd M, Y',
      onClose: (selectedDates, dateStr, instance) => {
        dateFrom = dateStr
        const formatDate = dateStr.split(' to ')
        if (formatDate.length > 1) [dateFrom, dateTo] = formatDate
        else {
          dateTo = dateFrom
          instance.setDate([dateTo, dateFrom], true)
        }
        updateApi(property.value)
      }
    }

    const toggleCalendar = () => {
      const { fp } = flatpickr.value
      fp.toggle()
    }

    onBeforeUnmount(() => {
      devices.value.series = []
      devices.value.chartOptions.noData.text = 'Loading...'
      mapData.value.data.values = []
    })

    return {
      date,
      general,
      domains,
      property,
      devices,
      allProperties,
      urls,
      sources,
      browsers,
      isLoading,
      datepickerConfig,
      updateApi,
      pieKey,
      toggleCalendar,
      flatpickr
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/overview/index.scss';
@import '@/assets/scss/components/shared/svg-map.scss';

.overview-map__section {
  width: 100%;
  height: 520px !important;
  position: relative;
}

.map-loading__container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background: #fff;
}
</style>

const domains = {
  title: 'Top Domains',
  detailsGetter: 'detailedDomains',
  graphGetter: 'domainsGraph',
  detailsDispatch: 'getDetailedDomains',
  graphDispatch: 'getDetailedGraphDomains',
  fileDispatch: 'getDetailedDomainsFile'
}

const sources = {
  title: 'Top Sources',
  detailsGetter: 'detailedSources',
  graphGetter: 'sourcesGraph',
  detailsDispatch: 'getDetailedSources',
  graphDispatch: 'getDetailedGraphSources',
  fileDispatch: 'getDetailedSourcesFile'
}

const urls = {
  title: 'Top URLs',
  detailsGetter: 'detailedUrls',
  graphGetter: 'urlsGraph',
  detailsDispatch: 'getDetailedURLs',
  graphDispatch: 'getDetailedGraphURLs',
  fileDispatch: 'getDetailedURLsFile'
}

const browsers = {
  title: 'Top Browsers',
  detailsGetter: 'detailedBrowsers',
  graphGetter: 'browsersGraph',
  detailsDispatch: 'getDetailedBrowsers',
  graphDispatch: 'getDetailedGraphBrowsers',
  fileDispatch: 'getDetailedBrowsersFile'
}

const detailedConfig = {
  domains,
  sources,
  urls,
  browsers
}

const initialChartConfig = {
  series: [
    {
      name: 'Page Views',
      data: [],
      labels: {
        formatter: (data) => data.toLocaleString()
      }
    }
  ],
  chart: {
    id: 'analytics',
    type: 'line',
    dropShadow: {
      enabled: true,
      color: '#000',
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2
    },
    toolbar: {
      show: false
    }
  },
  colors: ['#1D79F2'],
  dataLabels: {
    enabled: true,
    formatter: (series) => {
      if (series < 1e3) return series
      if (series >= 1e3 && series < 1e6) return `${+(series / 1e3).toFixed(1)}K`
      if (series >= 1e6 && series < 1e9) return `${+(series / 1e6).toFixed(1)}M`
      if (series >= 1e9 && series < 1e12)
        return `${+(series / 1e9).toFixed(1)}B`
      return series
    }
  },
  stroke: {
    curve: 'smooth'
  },
  title: {
    text: 'Pageviews',
    align: 'left',
    style: {
      fontSize: '20px',
      fontWeight: '700',
      fontFamily: 'Inter',
      color: '#031C3C'
    }
  },
  grid: {
    borderColor: '#DADFE7',
    strokeDashArray: 8,
    row: {
      colors: ['transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
    padding: {
      left: 25
    }
  },
  markers: {
    size: 1
  },
  yaxis: {
    tickAmount: 4,
    labels: {
      show: true,
      formatter: (series) => series.toLocaleString(),
      type: 'datetime',
      style: {
        colors: '#A3B0C2',
        cssClass: 'analytics-chart__yaxis'
      }
    }
  },
  xaxis: {
    categories: [],
    labels: {
      formatter: (val) => {
        const formattedDate = new Date(val)
        return `${formattedDate.getDate()}/${
          formattedDate.getMonth() + 1
        }/${formattedDate.getFullYear()}`
      },
      style: {
        colors: '#A3B0C2'
      }
    }
  },
  noData: {
    text: 'Loading...'
  }
}

export { detailedConfig, initialChartConfig }

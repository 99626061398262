import axiosIns from '@/libs/axios'
import { API_GATEWAY_URL } from '@/utils/constants'
import {
  destructJSONObject,
  formatPropertiesQuery
} from '@nodus/utilities-front'
import { assignGrowthIndicators } from '../utils'

export default {
  state: {
    table: {
      pageLength: 10,
      columns: [
        {
          label: 'Browser',
          field: 'browser',
          sortable: false,
          thClass: 'not-sortable-headers real-time__header'
        },
        {
          label: 'Active Users',
          field: 'activeUsers',
          sortable: false,
          thClass: 'not-sortable-headers'
        },
        {
          label: 'Percentage',
          field: 'percentage',
          sortable: false,
          thClass: 'not-sortable-headers'
        }
      ],
      rows: [],
      indicators: [],
      oldRows: [],
      oldIndicators: [],
      searchTerm: ''
    }
  },
  getters: {
    browsers: (state) => state.table
  },
  actions: {
    async getBrowsers({ commit }, { id, propertyIds }) {
      const properties = formatPropertiesQuery(propertyIds)
      const response = await axiosIns.get(
        `${API_GATEWAY_URL}/api/v1/${id}/realtime/browsers?${properties}`
      )

      commit('SET_BROWSERS', destructJSONObject(response, 'browsers'))
      commit('SET_GROWTH_INDICATOR')
    }
  },
  mutations: {
    SET_BROWSERS(state, browsers) {
      state.table.oldRows = state.table.rows
      state.table.rows = browsers
    },
    SET_GROWTH_INDICATOR(state) {
      assignGrowthIndicators(state.table, 'browser')
    }
  }
}

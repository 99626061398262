var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "domains__wrapper",
      class: { "domains__wrapper--loading": _vm.isLoading },
    },
    [
      _c("div", { staticClass: "domains__wrapper--padding" }, [
        _c("h5", [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c("div", [
        _c(
          "section",
          { staticClass: "table__wrapper domains-table" },
          [
            _c("Table", {
              attrs: {
                data: _vm.data,
                "type-of-table": "realtime",
                "is-loading": _vm.isLoading,
                "realtime-updated": _vm.realtimeUpdated,
              },
              scopedSlots: _vm._u([
                {
                  key: "row-data",
                  fn: function ({ data }) {
                    return [_c("RealTimeRow", { attrs: { data: data } })]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
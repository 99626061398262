import axiosIns from '@/libs/axios'
import { API_BASE_URL } from '@/utils/constants'
import {
  destructJSONObject,
  formatAnalyticsDetailedRequest,
  formatAnalyticsRequest
} from '@nodus/utilities-front'
import graph from './graph'

export default {
  modules: { graph },
  state: {
    table: {
      pageLength: 10,
      columns: [
        {
          label: 'Sources',
          field: 'pageSource',
          width: '65%',
          sortable: false,
          thClass: 'not-sortable-headers custom-analytics__header'
        },
        {
          label: 'Pageviews',
          field: 'pageViews',
          type: 'number',
          width: '35%'
        }
      ],
      rows: [],
      searchTerm: ''
    },
    detailedTable: {
      pageLength: 10,
      columns: [
        {
          label: 'Sources',
          field: 'pageSource',
          width: '55%',
          thClass: 'custom-analytics__header'
        },
        {
          label: 'Pageviews',
          field: 'pageViews',
          type: 'number',
          width: '15%'
        },
        {
          label: 'Unique Users',
          field: 'uniqueUsers',
          type: 'number',
          width: '15%'
        },
        {
          label: 'Sessions',
          field: 'sessions',
          type: 'number',
          width: '15%'
        }
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: ''
    }
  },
  getters: {
    sources: (state) => state.table,
    detailedSources: (state) => state.detailedTable
  },
  actions: {
    async getSources(
      { commit },
      { id, propertyIds, dateFrom, dateTo, cancelToken }
    ) {
      const queryParamString = formatAnalyticsRequest(
        propertyIds,
        dateFrom,
        dateTo
      )
      await axiosIns
        .get(
          `${API_BASE_URL}/api/v1/${id}/analytics/topsources?${queryParamString}`,
          {
            cancelToken
          }
        )
        .then((response) => {
          commit('SET_SOURCES', destructJSONObject(response, 'sources'))
        })
        .catch(() => {
          commit('SET_SOURCES', [])
        })
    },
    async getDetailedSources(
      { commit, state },
      { id, propertyIds, dateFrom, dateTo }
    ) {
      const queryParamString = formatAnalyticsDetailedRequest(
        state.detailedTable.page,
        state.detailedTable.perPage,
        propertyIds,
        dateFrom,
        dateTo,
        state.detailedTable.orderField,
        state.detailedTable.orderType
      )
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/analytics/details/sources?${queryParamString}`
      )
      commit('SET_SOURCES_DETAILED', destructJSONObject(response, 'sources'))
      commit('SET_TOTAL_RECORDS', destructJSONObject(response, 'totalCount'))
    },
    async getDetailedSourcesFile(
      _,
      { id, propertyIds, dateFrom, dateTo, format }
    ) {
      const queryParamString = formatAnalyticsRequest(
        propertyIds,
        dateFrom,
        dateTo
      )
      return axiosIns
        .get(
          `${API_BASE_URL}/api/v1/${id}/analytics/details/sources/file?FileDownloadFormat=${format}&${queryParamString}`,
          { responseType: 'blob' }
        )
        .then((response) => response.data)
    }
  },
  mutations: {
    SET_SOURCES(state, sources) {
      state.table.rows = sources
    },
    SET_SOURCES_DETAILED(state, detailedSources) {
      state.detailedTable.rows = detailedSources
    },
    SET_TOTAL_RECORDS(state, totalRecords) {
      state.detailedTable.totalRecords = totalRecords
    }
  }
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "header__wrapper" },
          [
            _c(
              "b-col",
              { staticClass: "col__position-left", attrs: { lg: "4" } },
              [
                _c("MultipleProperties", {
                  attrs: {
                    id: "select-properties",
                    options: _vm.allProperties,
                    "is-loading": _vm.isLoading.properties,
                  },
                  on: { "update-data": _vm.updateApi },
                }),
              ],
              1
            ),
            _c("b-col", { staticClass: "col__position-right" }, [
              _c("span", { staticClass: "calendar__range-label" }, [
                _vm._v(_vm._s(_vm.$t("analytics.showDataPer")) + ":"),
              ]),
              _c(
                "div",
                { staticClass: "header__wrapper-input calendar__range-input" },
                [
                  _c("flat-pickr", {
                    ref: "flatpickr",
                    staticClass: "form-control date",
                    attrs: {
                      id: "select-datepicker",
                      config: _vm.datepickerConfig,
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "calendar-toggle",
                      on: {
                        click: function ($event) {
                          return _vm.toggleCalendar()
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/svgs/calendar.svg"),
                          alt: "calendar",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "b-col",
          {
            staticClass:
              "general__wrapper general__wrapper--box-shadow general__wrapper--padding general__wrapper--height",
            attrs: { lg: "12" },
          },
          [
            !_vm.isLoading.general && _vm.general.length > 0
              ? _c("div", { staticClass: "general-info__container" }, [
                  _c("div", { staticClass: "general__wrapper-info" }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("analytics.uniqueUsers")))]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.general[0].uniqueUsers.toLocaleString())
                      ),
                    ]),
                  ]),
                  !_vm.isLoading.general
                    ? _c("div", { staticClass: "general__wrapper-info" }, [
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("analytics.pageViews"))),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.general[0].pageViews.toLocaleString())
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  !_vm.isLoading.general
                    ? _c("div", { staticClass: "general__wrapper-info" }, [
                        _c("p", [_vm._v(_vm._s(_vm.$t("analytics.sessions")))]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.general[0].sessions.toLocaleString())
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  !_vm.isLoading.general
                    ? _c("div", { staticClass: "general__wrapper-info" }, [
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("analytics.avgPerUsers"))),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.general[0].averagePageView.toLocaleString()
                            )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _c(
                  "div",
                  { staticClass: "loading__container" },
                  [_c("LoadingBar", { attrs: { classList: "medium" } })],
                  1
                ),
          ]
        ),
        _c(
          "b-col",
          {
            staticClass: "general__wrapper general__wrapper-domains",
            attrs: { lg: "12" },
          },
          [
            _c("OverviewTable", {
              attrs: {
                title: "Top Domains",
                data: _vm.domains,
                type: "domains",
                "is-loading": _vm.isLoading.domains,
              },
            }),
          ],
          1
        ),
        _c(
          "b-col",
          {
            staticClass: "general__wrapper general__wrapper-domains",
            attrs: { lg: "12" },
          },
          [
            _c("OverviewTable", {
              attrs: {
                title: "Top URLs",
                data: _vm.urls,
                type: "urls",
                "is-loading": _vm.isLoading.urls,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "map__section domains__wrapper general__wrapper--box-shadow",
          },
          [
            _c("h5", { staticClass: "map-title" }, [
              _vm._v(_vm._s(_vm.$t("analytics.mapOverview"))),
            ]),
            _c("div", { staticClass: "overview-map__section" }, [
              _c("div", { attrs: { id: "svgMap" } }),
              _vm.isLoading.map
                ? _c(
                    "div",
                    { staticClass: "map-loading__container" },
                    [_c("LoadingBar", { attrs: { classList: "large" } })],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "pie-and-sources__container" },
          [
            _c(
              "div",
              {
                staticClass:
                  "general__wrapper wrapper__padding analytics__pie-chart",
              },
              [
                _c("vue-apex-charts", {
                  key: _vm.pieKey,
                  staticClass: "apex-chart__container",
                  attrs: {
                    type: "pie",
                    options: _vm.devices.chartOptions,
                    series: _vm.devices.series,
                    height: "450",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-col",
              {
                staticClass: "general__wrapper general__wrapper-domains",
                attrs: { lg: "6", md: "12", sm: "12" },
              },
              [
                _c("OverviewTable", {
                  attrs: {
                    title: "Top Sources",
                    data: _vm.sources,
                    type: "sources",
                    "is-loading": _vm.isLoading.sources,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-col",
          {
            staticClass: "general__wrapper general__wrapper-domains",
            attrs: { lg: "12" },
          },
          [
            _c("OverviewTable", {
              attrs: {
                title: "Top Browsers",
                data: _vm.browsers,
                type: "browsers",
                "is-loading": _vm.isLoading.browsers,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section
    class="domains__wrapper"
    v-bind:class="{ 'domains__wrapper--loading': isLoading }"
  >
    <div class="domains__wrapper--padding">
      <h5>{{ title }}</h5>
    </div>
    <div>
      <section class="table__wrapper domains-table">
        <Table
          :data="data"
          type-of-table="realtime"
          :is-loading="isLoading"
          :realtime-updated="realtimeUpdated"
        >
          <template #row-data="{ data }">
            <RealTimeRow :data="data" />
          </template>
        </Table>
      </section>
    </div>
  </section>
</template>

<script>
import store from '@/store'
import { Table } from '@nodus/utilities-front'
import { computed } from 'vue'
import RealTimeRow from '../table-data/RealTimeRow.vue'

export default {
  name: 'TopActiveTable',
  components: {
    Table,
    RealTimeRow
  },
  props: {
    title: {
      type: [String]
    },
    isLoading: {
      type: [Boolean]
    },
    data: {
      type: [Object]
    }
  },
  setup() {
    const realtimeUpdated = computed(
      () => store.getters['realtime/realtimeUpdated']
    )

    return {
      realtimeUpdated
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/views/real-time/top-active.scss';
</style>

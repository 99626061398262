/* eslint-disable */
import axiosIns from '@/libs/axios'
import store from '@/store'
import { API_BASE_URL } from '@/utils/constants'
import {
  destructJSONObject,
  formatAnalyticsRequest,
  getCountryColor
} from '@nodus/utilities-front'

export default {
  state: {
    targetElementID: 'svgMap',
    mouseWheelZoomEnabled: false,
    minZoom: 0.9,
    initialZoom: 0.9,
    flagType: 'image',
    colorMax: '#3A81F6',
    colorMin: '#D8E6FD',
    colorNoData: '#e9eef7',
    noDataText: '',
    data: {
      data: {
        gdp: {
          name: 'Unique Users:',
          thousandSeparator: ',',
          thresholdMax: 1000000,
          thresholdMin: 1
        }
      },
      applyData: 'gdp',
      values: {}
    }
  },
  getters: {
    map: (state) => state
  },
  actions: {
    async getMap(
      { commit },
      { id, propertyIds, dateFrom, dateTo, cancelToken }
    ) {
      const queryParamString = formatAnalyticsRequest(
        propertyIds,
        dateFrom,
        dateTo
      )
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/analytics/geo?${queryParamString}`,
        {
          cancelToken
        }
      )
      commit('SET_MAP', destructJSONObject(response, 'countries'))
    }
  },
  mutations: {
    SET_MAP(state, map) {
      const array = []
      const maxValue = map.length > 0 ? map[0].uniqueUsers : 0
      const allUsers = store.getters['overview/general'][0]?.uniqueUsers || 0
      const ratio = maxValue / allUsers

      map.forEach(({ geoLocationCountry, uniqueUsers }) => {
        if (!geoLocationCountry) geoLocationCountry = 'unidentified'
        array[geoLocationCountry] = {
          color: getCountryColor(uniqueUsers, maxValue, ratio),
          gdp: uniqueUsers
        }
      })
      state.data.values = array
    }
  }
}

export default [
  {
    path: '/bisko/:organizationId/analytics/real-time',
    name: 'real-time',
    component: () => import('@/views/real-time/RealTime'),
    meta: {
      navActiveLink: 'real-time',
      pageTitle: 'Real Time',
      breadcrumb: [
        {
          text: 'Analytics'
        },
        {
          text: 'Real Time',
          active: true
        }
      ]
    }
  }
]

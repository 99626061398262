<template>
  <!-- Column: Domains -->
  <span
    v-if="data.column.field === 'pageDomain'"
    class="domains-table__img-container"
  >
    <div class="domains-table__img-container_content">
      <img :src="getDomainFavicon(data.row.pageDomain)" />
    </div>
    <span
      ><a :href="`https://${data.row.pageDomain}`" target="_blank">{{
        data.row.pageDomain
      }}</a></span
    >
  </span>

  <!-- Column: URL -->
  <span
    v-else-if="data.column.field === 'pageCleanUrl'"
    class="domains-table__img-container"
  >
    <div class="domains-table__img-container_content">
      <img :src="getDomainFavicon(data.row.pageCleanUrl)" />
    </div>
    <span :id="`analytics__url-${data.row.originalIndex}`">
      <a :href="`${data.row.pageCleanUrl}`" target="_blank">
        {{ updateCleanUrlFormat(data.row.pageCleanUrl) }}
      </a>
      <b-tooltip :target="`analytics__url-${data.row.originalIndex}`">{{
        data.row.pageCleanUrl
      }}</b-tooltip>
    </span>
  </span>

  <!-- Column: Sources -->
  <span
    v-else-if="data.column.field === 'pageSource'"
    class="domains-table__img-container"
  >
    <div class="domains-table__img-container_content">
      <img :src="getDomainFavicon(data.row.pageSource)" />
    </div>
    <span>{{ data.row.pageSource }}</span>
  </span>

  <!-- Column: Device Browsers -->
  <span
    v-else-if="data.column.field === 'deviceBrowser'"
    class="domains-table__img-container"
  >
    <div class="domains-table__img-container_content">
      <img v-if="data.row.icon" :src="data.row.icon" />
      <GjIcon v-if="!data.row.icon" name="Globe" />
    </div>
    <span>{{ data.row.deviceBrowser }}</span>
  </span>

  <span v-else-if="data.column.field === 'percentage'">
    <span>{{ data.row.percentage }}%</span>
  </span>

  <!-- Column: Common -->
  <span v-else>
    {{ data.formattedRow[data.column.field].toLocaleString() }}
  </span>
</template>

<script>
import {GjIcon} from '@nodus/utilities-front'
import {BTooltip} from 'bootstrap-vue'

export default {
  name: 'AnalyticsRow',
  components: {
    GjIcon,
    BTooltip,
  },
  props: {
    data: {
      type: [Array, Object],
    },
  },
  setup() {
    const getDomainFavicon = (pageDomain) =>
      `https://www.google.com/s2/favicons?sz=32&domain=${pageDomain}`
    const updateCleanUrlFormat = (url) => {
      const newUrl = url.replace(/^(?:https?:\/\/)?/i, '')
      if (newUrl.length > 60) {
        return `${newUrl.slice(0, 30)}...${newUrl.slice(
          newUrl.length - 30,
          newUrl.length
        )}`
      }
      return newUrl
    }

    return {
      getDomainFavicon,
      updateCleanUrlFormat,
    }
  },
}
</script>

<style lang="scss">
a {
  color: inherit;

  &:hover {
    color: inherit;
  }
}
</style>

/* eslint-disable */
import axiosIns from '@/libs/axios'
import { API_GATEWAY_URL } from '@/utils/constants'
import {
  destructJSONObject,
  formatPropertiesQuery,
  getCountryColor
} from '@nodus/utilities-front'
import { getPulseEffect } from '../utils'

export default {
  state: {
    map: {
      targetElementID: 'svgMap',
      mouseWheelZoomEnabled: false,
      minZoom: 0.9,
      initialZoom: 0.9,
      flagType: 'image',
      colorMax: '#3A81F6',
      colorMin: '#D8E6FD',
      colorNoData: '#e9eef7',
      noDataText: '',
      data: {
        data: {
          gdp: {
            name: 'Active Users',
            thousandSeparator: ',',
            thresholdMax: 1000000,
            thresholdMin: 1
          },
          percentage: {
            name: 'Percentage',
            format: '{0} %'
          }
        },
        applyData: 'gdp',
        values: {}
      }
    },
    values: [],
    oldValues: []
  },
  getters: {
    map: (state) => state.map
  },
  actions: {
    async getMap({ commit }, { id, propertyIds }) {
      const properties = formatPropertiesQuery(propertyIds)
      const response = await axiosIns.get(
        `${API_GATEWAY_URL}/api/v1/${id}/realtime/geolocations?${properties}`
      )
      commit('SET_MAP', destructJSONObject(response, 'countries'))
    }
  },
  mutations: {
    SET_MAP(state, map) {
      state.oldValues = state.values
      state.values = []
      const array = []
      const maxValue = map[0]?.activeUsers || 0
      const ratio = map[0]?.percentage / 100 || 0

      const viewportElem = document
        .getElementsByClassName('svg-pan-zoom_viewport')
        .item(0)
      map.forEach(({ geoLocationCountry, activeUsers, percentage }) => {
        if (!geoLocationCountry) geoLocationCountry = 'unidentified'
        array[geoLocationCountry] = {
          color: getCountryColor(activeUsers, maxValue, ratio),
          gdp: activeUsers,
          percentage
        }

        let growth
        let show = true
        const oldActiveUsers =
          state.oldValues.find(
            (ov) => ov.geoLocationCountry === geoLocationCountry
          )?.activeUsers || -1
        if (activeUsers > oldActiveUsers) growth = 1
        else if (activeUsers < oldActiveUsers) growth = -1
        else {
          growth =
            state.oldValues.find(
              (ov) => ov.geoLocationCountry === geoLocationCountry
            )?.growth || 1
          show = false
        }

        state.values.push({ geoLocationCountry, activeUsers, growth })
        if (show && geoLocationCountry !== 'unidentified')
          viewportElem.appendChild(getPulseEffect(geoLocationCountry, growth))
      })
      state.map.data.values = array
    }
  }
}

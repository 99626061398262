/* eslint-disable no-param-reassign */

export const getPulseEffect = (countryID, growth) => {
  let bBox = null
  if (document.getElementById(`svgMap-map-country-${countryID}`)) {
    bBox = document.getElementById(`svgMap-map-country-${countryID}`).getBBox()
  }

  const subClass = growth > 0 ? 'green' : 'red'
  let pulseGroup = document.getElementById(`pulse-country-${countryID}`)

  if (pulseGroup) {
    pulseGroup.setAttribute('class', `pulse-circle pulse-circle--${subClass}`)
  } else {
    pulseGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g')
    const circleCore =
      document.getElementById(`${countryID}-core`) ??
      document.createElementNS('http://www.w3.org/2000/svg', 'circle')
    const circleRadar =
      document.getElementById(`${countryID}-radar`) ??
      document.createElementNS('http://www.w3.org/2000/svg', 'circle')

    pulseGroup.setAttribute('id', `pulse-country-${countryID}`)
    if (bBox)
      pulseGroup.setAttribute(
        'transform',
        `translate(${bBox.x + bBox.width / 2}, ${bBox.y + bBox.height / 2})`
      )
    pulseGroup.setAttribute('class', `pulse-circle pulse-circle--${subClass}`)
    circleCore.setAttribute('id', `${countryID}-core`)
    circleCore.setAttribute('cx', '0')
    circleCore.setAttribute('cy', '0')
    circleCore.setAttribute('r', '3')
    circleRadar.setAttribute('id', `${countryID}-radar`)
    circleRadar.setAttribute('cx', '0')
    circleRadar.setAttribute('cy', '0')
    circleRadar.setAttribute('r', '3')

    if (bBox) {
      pulseGroup.appendChild(circleCore)
      pulseGroup.appendChild(circleRadar)
    }
  }

  return pulseGroup
}

export const assignGrowthIndicators = (table, fieldName) => {
  table.oldIndicators = table.indicators
  table.indicators = []
  const { oldRows, rows } = table

  rows.forEach((row) => {
    const { activeUsers } = row
    const field = row[fieldName]
    const oldActiveUsers =
      oldRows.find((or) => or[fieldName] === field)?.activeUsers || -1
    let growth
    let show = true

    if (activeUsers > oldActiveUsers) growth = 1
    else if (activeUsers < oldActiveUsers) growth = -1
    else {
      growth =
        table.oldIndicators.find((oi) => oi[fieldName] === field)?.growth || 1
      show = false
    }

    table.indicators.push({ [fieldName]: field, growth })
    row.growth = growth
    row.show = show
  })
}

export const isValidHttpUrl = (string) => {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bisko-app__analytics .general__wrapper {
  background: white;
  margin: 25px 0;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
}
.bisko-app__analytics .general__wrapper--box-shadow {
  box-shadow: 0px 8px 24px rgba(17, 37, 74, 0.08);
  border: 1px solid #edeff3;
}
.bisko-app__analytics .general__wrapper--padding {
  padding: 32px 16px 16px;
}
.bisko-app__analytics .general__wrapper-domains {
  padding: 0;
}
.bisko-app__analytics .general__wrapper-info {
  padding: 0 25px;
  align-self: center;
  border-left: 1px solid #e0e5eb;
}
.bisko-app__analytics .general__wrapper-info:first-child {
  border-left: none;
  padding-left: 12px;
}
.bisko-app__analytics .general__wrapper-info p {
  margin-bottom: 0;
  font-size: 13px;
  margin-top: -10px;
}
.bisko-app__analytics .general__wrapper-info span {
  font-size: 28px;
  font-weight: 500;
  color: #031c3c;
}
.bisko-app__analytics .wrapper__padding {
  padding: 15px;
}
.bisko-app__analytics .general-info__container {
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.bisko-app__analytics .general__wrapper--height {
  min-height: 96px;
}
.bisko-app__analytics .echarts {
  width: 100%;
}
.bisko-app__analytics .pie-and-sources__container {
  display: flex;
  justify-content: space-between;
}
.bisko-app__analytics .pie-and-sources__container > div {
  width: calc(50% - 25px);
}
.bisko-app__analytics .pie-and-sources__container .apex-chart__container {
  width: 100%;
  border-right: 1px solid #eaeaea;
}
.bisko-app__analytics .analytics__pie-chart {
  display: block;
}
.bisko-app__analytics .analytics__pie-chart .apexcharts-pie-series.legend-mouseover-inactive {
  opacity: 0.1;
}
.bisko-app__analytics .analytics__pie-chart .apexcharts-datalabels.legend-mouseover-inactive {
  opacity: 1;
}
.bisko-app__analytics .analytics__pie-chart .apexcharts-pie-area:hover, .bisko-app__analytics .analytics__pie-chart .apexcharts-pie-area[selected=true] {
  transform-origin: center;
  transform: scale(1.05);
  transition: transform 0.25s ease-out;
  filter: brightness(1.15) drop-shadow(1px 1px 10px rgb(62, 62, 62));
}
.bisko-app__analytics .analytics__pie-chart .apexcharts-legend {
  overflow: hidden;
}
.bisko-app__analytics .analytics__pie-chart .apexcharts-legend-marker {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.bisko-app__analytics .analytics__pie-chart .apexcharts-legend-marker svg {
  vertical-align: middle;
}
.bisko-app__analytics .flatpickr-calendar.open {
  z-index: 10;
}
@media only screen and (max-width: 991px) {
.bisko-app__analytics .general-info__container {
    flex-wrap: wrap;
}
.bisko-app__analytics .general-info__container .general__wrapper-info {
    padding: 10px 25px;
    width: 280px;
    border-left: none;
}
.bisko-app__analytics .pie-and-sources__container {
    flex-direction: column;
}
.bisko-app__analytics .pie-and-sources__container > div {
    width: 100% !important;
}
.bisko-app__analytics .pie-and-sources__container > div .apex-chart__container {
    border-right: none;
}
}
@media only screen and (max-width: 768px) {
.bisko-app__analytics .general-info__container .general__wrapper-info {
    width: 200px;
}
}
@media only screen and (max-width: 425px) {
.bisko-app__analytics .realtime__dashboard-section_right {
    flex-wrap: wrap;
}
.bisko-app__analytics .realtime__dashboard-section_right .realtime__dashboard-section__device {
    border-left: none !important;
}
}
.svgMap-map-wrapper {
  background: white;
  padding-top: 38%;
}
.svgMap-tooltip {
  box-shadow: 0 4px 20px rgba(5, 45, 97, 0.16);
  position: absolute;
  z-index: 2;
  border-radius: 4px;
  background: #fff;
  border-bottom: 1px solid #fff;
  transform: translate(-50%, -100%);
  pointer-events: none;
}
.svgMap-tooltip .svgMap-tooltip-content-container {
  padding: 10px 20px;
}
.svgMap-tooltip .svgMap-tooltip-content-container .svgMap-tooltip-flag-container {
  text-align: center;
  margin: 0;
}
.svgMap-tooltip .svgMap-tooltip-content-container .svgMap-tooltip-flag-container.svgMap-tooltip-flag-container-emoji {
  font-size: 25px;
  align-self: center;
}
.svgMap-tooltip .svgMap-tooltip-content-container .svgMap-tooltip-flag-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.svgMap-tooltip .svgMap-tooltip-title {
  white-space: nowrap;
  font-size: 14px;
  padding-left: 5px;
  color: #667c99;
  font-weight: 500;
}
.svgMap-tooltip .svgMap-tooltip-content table {
  font-size: 14px;
  color: #667c99;
}
.svgMap-tooltip .svgMap-tooltip-content table tr {
  border-bottom: none;
}
.svgMap-tooltip .svgMap-tooltip-content table tr td:first-child {
  text-align: left;
}
.svgMap-tooltip .svgMap-tooltip-content table td span {
  color: #052d61;
}
.svgMap-tooltip .svgMap-tooltip-pointer:after {
  border: 1px solid #fff;
}
.svgMap-map-controls-wrapper {
  left: auto !important;
  right: 24px;
  bottom: 24px !important;
  box-shadow: none !important;
}
.svgMap-map-controls-wrapper .svgMap-map-controls-zoom {
  flex-direction: row-reverse;
  gap: 4px;
  background: transparent !important;
}
.svgMap-map-controls-wrapper .svgMap-map-controls-zoom .svgMap-control-button {
  width: 34px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #e0e5eb;
  background: #fff;
}
.svgMap-map-controls-wrapper .svgMap-map-controls-zoom .svgMap-control-button::before {
  height: 1px;
  background: #667c99;
  border-radius: 1px;
}
.svgMap-map-controls-wrapper .svgMap-map-controls-zoom .svgMap-control-button::after {
  width: 1px;
  background: #667c99;
  border-radius: 1px;
}
@media (hover: hover) {
.svgMap-map-wrapper .svgMap-country:hover {
    stroke: #1698db;
    stroke-width: 1;
}
}
.svgMap-map-wrapper .svgMap-map-image .svg-pan-zoom_viewport {
  left: 0;
}
.bisko-app__analytics .overview-map__section {
  width: 100%;
  height: 520px !important;
  position: relative;
}
.bisko-app__analytics .map-loading__container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background: #fff;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import Vue from 'vue'
import VueRouter from 'vue-router'

import overview from './overview'
import realTime from './real-time'

Vue.use(VueRouter)

const routes = [
  {
    path: '/bisko/:organizationId/analytics',
    redirect: '/bisko/:organizationId/analytics/overview'
  },
  ...overview,
  ...realTime,
  {
    path: '/bisko/:organizationId/analytics/:pathMatch(.*)*',
    redirect: '/error/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

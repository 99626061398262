import axiosIns from '@/libs/axios'
import { API_BASE_URL } from '@/utils/constants'
import {
  destructJSONObject,
  formatAnalyticsRequest
} from '@nodus/utilities-front'

export default {
  state: {
    data: []
  },
  getters: {
    general: (state) => state.data
  },
  actions: {
    async getGeneral(
      { commit },
      { id, propertyIds, dateFrom, dateTo, cancelToken }
    ) {
      const queryParamString = formatAnalyticsRequest(
        propertyIds,
        dateFrom,
        dateTo
      )
      await axiosIns
        .get(
          `${API_BASE_URL}/api/v1/${id}/analytics/general?${queryParamString}`,
          {
            cancelToken
          }
        )
        .then((response) => {
          commit('SET_GENERAL', destructJSONObject(response, 'generalDetails'))
        })
        .catch(() => {
          commit('SET_GENERAL', [])
        })
    }
  },
  mutations: {
    SET_GENERAL(state, general) {
      state.data = general.length
        ? general
        : [
            {
              sessions: 0,
              uniqueUsers: 0,
              pageViews: 0,
              averagePageView: 0
            }
          ]
    }
  }
}

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bisko-app__analytics .domains__wrapper-detailed-view {
  text-align: right;
  padding: 15px 20px;
  color: #667c99;
  font-size: 14px;
}
.bisko-app__analytics .domains__wrapper-detailed-view .see-more__wrapper {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  text-decoration: none;
}
.bisko-app__analytics .domains__wrapper--loading {
  min-height: 400px;
}
.bisko-app__analytics .view__details--disabled .see-more__wrapper {
  pointer-events: none;
}
.bisko-app__analytics .view__details--disabled .see-more__wrapper p,
.bisko-app__analytics .view__details--disabled .see-more__wrapper svg {
  opacity: 0.5;
}
.bisko-app__analytics .pro-badge {
  background-color: #ebeef4;
  font-size: 11px;
  font-weight: 500;
  color: #6e90f3;
  border-radius: 12px;
  padding: 4px 8px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

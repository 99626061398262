<template>
  <section
    class="domains__wrapper"
    v-bind:class="{ 'domains__wrapper--loading': isLoading }"
  >
    <div class="domains__wrapper--padding">
      <h5>{{ title }}</h5>
    </div>
    <section class="table__wrapper analytics-table domains-table">
      <Table :data="data" type-of-table="analytics" :is-loading="isLoading">
        <template #row-data="{ data }">
          <AnalyticsRow :data="data" />
        </template>
      </Table>
    </section>
    <div
      v-if="!isFooterShown"
      :id="`more-results-${type}`"
      class="domains__wrapper-detailed-view"
      :class="{ 'view__details--disabled': isOrganizationPlanLite }"
    >
      <b-link
        class="see-more__wrapper"
        :to="{
          name: 'overview-detailed',
          params: { organizationId, type }
        }"
      >
        <p>{{ $t('moreResults') }}</p>
        <GjIcon name="ArrowRight" class="mr-50" size="20"></GjIcon>
        <b-badge
          v-if="isOrganizationPlanLite"
          class="pro-badge"
          small
          variant="pro"
          >PRO</b-badge
        >
      </b-link>
    </div>
  </section>
</template>

<script>
import {authStore} from '@nodus/authentication'
import {GjIcon, Table} from '@nodus/utilities-front'
import {BBadge, BLink} from 'bootstrap-vue'
import {computed, getCurrentInstance} from 'vue'
import AnalyticsRow from '../table-data/AnalyticsRow.vue'

export default {
  name: 'OverviewTable',
  components: {
    Table,
    AnalyticsRow,
    GjIcon,
    BBadge,
    BLink
  },
  props: {
    title: {
      type: [String]
    },
    isFooterShown: {
      type: [Boolean]
    },
    data: {
      type: [Object]
    },
    isLoading: {
      type: [Boolean]
    },
    type: {
      type: [String]
    }
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params

    const isOrganizationPlanLite = computed(
      () => authStore.getters['organization/plan'] === 'Lite'
    )

    return {
      organizationId,
      isOrganizationPlanLite
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/overview/overview-table.scss';
</style>

const desktop = '<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.549805 2.14531C0.549805 1.06836 1.42285 0.195312 2.4998 0.195312H11.4998C12.5768 0.195312 13.4498 1.06836 13.4498 2.14531V8.57388C13.4498 9.65084 12.5768 10.5239 11.4998 10.5239H2.4998C1.42285 10.5239 0.549805 9.65084 0.549805 8.57388V2.14531ZM2.4998 1.09531C1.91991 1.09531 1.4498 1.56541 1.4498 2.14531V8.57388C1.4498 9.15378 1.91991 9.62388 2.4998 9.62388H11.4998C12.0797 9.62388 12.5498 9.15378 12.5498 8.57388V2.14531C12.5498 1.56541 12.0797 1.09531 11.4998 1.09531H2.4998Z" fill="#667C99"/><path fill-rule="evenodd" clip-rule="evenodd" d="M4.40723 11.3602C4.40723 11.1116 4.6087 10.9102 4.85723 10.9102H9.14294C9.39147 10.9102 9.59294 11.1116 9.59294 11.3602C9.59294 11.6087 9.39147 11.8102 9.14294 11.8102H4.85723C4.6087 11.8102 4.40723 11.6087 4.40723 11.3602Z" fill="#667C99"/></svg>'
const tablet = '<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.835938 2.50078C0.835938 1.42383 1.70898 0.550781 2.78594 0.550781H9.21451C10.2915 0.550781 11.1645 1.42383 11.1645 2.50078V11.5008C11.1645 12.5777 10.2915 13.4508 9.21451 13.4508H2.78594C1.70898 13.4508 0.835938 12.5777 0.835938 11.5008V2.50078ZM2.78594 1.45078C2.20604 1.45078 1.73594 1.92088 1.73594 2.50078V11.5008C1.73594 12.0807 2.20604 12.5508 2.78594 12.5508H9.21451C9.79441 12.5508 10.2645 12.0807 10.2645 11.5008V2.50078C10.2645 1.92088 9.79441 1.45078 9.21451 1.45078H2.78594Z" fill="#667C99"/><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00028 11.7154C6.35532 11.7154 6.64314 11.4276 6.64314 11.0725C6.64314 10.7175 6.35532 10.4297 6.00028 10.4297C5.64524 10.4297 5.35742 10.7175 5.35742 11.0725C5.35742 11.4276 5.64524 11.7154 6.00028 11.7154Z" fill="#667C99"/></svg>'
const mobile = '<svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.213867 3.00065C0.213867 1.80403 1.18392 0.833984 2.38053 0.833984H7.61863C8.81524 0.833984 9.7853 1.80403 9.7853 3.00065V13.0007C9.7853 14.1973 8.81525 15.1673 7.61863 15.1673H2.38054C1.18392 15.1673 0.213867 14.1973 0.213867 13.0007V3.00065ZM2.38053 1.83398C1.7362 1.83398 1.21387 2.35632 1.21387 3.00065V13.0007C1.21387 13.645 1.7362 14.1673 2.38054 14.1673H7.61863C8.26296 14.1673 8.7853 13.645 8.7853 13.0007V3.00065C8.7853 2.35632 8.26296 1.83398 7.61863 1.83398H2.38053Z" fill="#667C99"/><path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 12.7617C3.5 12.4856 3.72386 12.2617 4 12.2617H6.02381C6.29995 12.2617 6.52381 12.4856 6.52381 12.7617C6.52381 13.0379 6.29995 13.2617 6.02381 13.2617H4C3.72386 13.2617 3.5 13.0379 3.5 12.7617Z" fill="#667C99"/></svg>'
const tv = '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.71164 2.7124C6.89079 2.92254 7.20637 2.94767 7.41652 2.76853C7.62666 2.58938 7.65179 2.2738 7.47265 2.06365L6.42425 0.83384C6.24511 0.623694 5.92952 0.598564 5.71938 0.77771C5.50923 0.956856 5.4841 1.27244 5.66325 1.48259L6.71164 2.7124Z" fill="#667C99"/><path fill-rule="evenodd" clip-rule="evenodd" d="M13.1552 3.26004H9.14489L10.3612 1.43565C10.5143 1.20589 10.4522 0.895456 10.2225 0.74228C9.99271 0.589103 9.68228 0.651191 9.5291 0.880955L7.94304 3.26004H2.83366C1.63704 3.26004 0.666992 4.2301 0.666992 5.42671V11.8469C0.666992 13.0435 1.63704 14.0135 2.83366 14.0135H13.1552C14.3518 14.0135 15.3219 13.0435 15.3219 11.8469V5.42671C15.3219 4.23009 14.3518 3.26004 13.1552 3.26004ZM1.66699 5.42671C1.66699 4.78238 2.18933 4.26004 2.83366 4.26004H13.1552C13.7996 4.26004 14.3219 4.78238 14.3219 5.42671V11.8469C14.3219 12.4912 13.7996 13.0135 13.1552 13.0135H2.83366C2.18933 13.0135 1.66699 12.4912 1.66699 11.8469V5.42671Z" fill="#667C99"/><path d="M5.10054 14.3426C4.8244 14.3426 4.60054 14.5665 4.60054 14.8426C4.60054 15.1187 4.8244 15.3426 5.10054 15.3426H10.8883C11.1645 15.3426 11.3883 15.1187 11.3883 14.8426C11.3883 14.5665 11.1645 14.3426 10.8883 14.3426H5.10054Z" fill="#667C99"/></svg>'
const other = '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.74208 8.98548C5.24415 8.98548 5.65117 8.57846 5.65117 8.07639C5.65117 7.57431 5.24415 7.1673 4.74208 7.1673C4.24 7.1673 3.83299 7.57431 3.83299 8.07639C3.83299 8.57846 4.24 8.98548 4.74208 8.98548Z" fill="#667C99"/><path d="M11.2572 8.98548C11.7593 8.98548 12.1663 8.57846 12.1663 8.07639C12.1663 7.57431 11.7593 7.1673 11.2572 7.1673C10.7552 7.1673 10.3481 7.57431 10.3481 8.07639C10.3481 8.57846 10.7552 8.98548 11.2572 8.98548Z" fill="#667C99"/><path d="M8.90874 8.07639C8.90874 8.57846 8.50173 8.98548 7.99965 8.98548C7.49758 8.98548 7.09056 8.57846 7.09056 8.07639C7.09056 7.57431 7.49758 7.1673 7.99965 7.1673C8.50173 7.1673 8.90874 7.57431 8.90874 8.07639Z" fill="#667C99"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7.99967 0.833984C4.04163 0.833984 0.833008 4.04261 0.833008 8.00065C0.833008 11.9587 4.04163 15.1673 7.99967 15.1673C11.9577 15.1673 15.1663 11.9587 15.1663 8.00065C15.1663 4.04261 11.9577 0.833984 7.99967 0.833984ZM1.83301 8.00065C1.83301 4.5949 4.59392 1.83398 7.99967 1.83398C11.4054 1.83398 14.1663 4.5949 14.1663 8.00065C14.1663 11.4064 11.4054 14.1673 7.99967 14.1673C4.59392 14.1673 1.83301 11.4064 1.83301 8.00065Z" fill="#667C99"/></svg>'

const deviceSvg = {
  desktop,
  tablet,
  mobile,
  tv,
  other
}

export default deviceSvg
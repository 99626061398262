import axiosIns from '@/libs/axios'
import { API_BASE_URL } from '@/utils/constants'
import { destructJSONObject } from '@nodus/utilities-front'
import Vue from 'vue'
import Vuex from 'vuex'

import overview from './overview'
import realtime from './real-time'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    properties: []
  },
  getters: {
    allProperties: (state) => state.properties
  },
  actions: {
    async getProperties({ commit }, { id }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/user/properties`
      )

      commit('SET_PROPERTIES', destructJSONObject(response, 'properties'))
    }
  },
  mutations: {
    SET_PROPERTIES(state, properties) {
      state.properties = properties
    }
  },
  modules: {
    overview,
    realtime
  }
})

<template>
  <!-- Column: Domains -->
  <span
    v-if="data.column.field === 'domain'"
    class="domains-table__img-container"
  >
    <div class="domains-table__img-container_content">
      <img :src="getDomainFavicon(data.row.domain)" />
    </div>
    <span
      ><a :href="`https://${data.row.domain}`" target="_blank">{{
        data.row.domain
      }}</a></span
    >
  </span>

  <!-- Column: ACTIVE PAGE -->
  <span
    v-else-if="data.column.field === 'url'"
    class="domains-table__img-container"
  >
    <div class="domains-table__img-container_content">
      <img :src="getDomainFavicon(data.row.url)" />
    </div>
    <span :id="`realtime__url-${data.row.originalIndex}`">
      <a :href="`${data.row.url}`" target="_blank">
        {{ updateCleanUrlFormat(data.row.url) }}
      </a>
      <b-tooltip :target="`realtime__url-${data.row.originalIndex}`">{{
        data.row.url
      }}</b-tooltip>
    </span>
  </span>

  <!-- Column: SOURCES -->
  <span
    v-else-if="data.column.field === 'source'"
    class="domains-table__img-container"
  >
    <div class="domains-table__img-container_content">
      <img :src="getDomainFavicon(data.row.source)" />
    </div>
    <span>{{ data.row.source }}</span>
  </span>

  <!-- Column: BROWSERS -->
  <span
    v-else-if="data.column.field === 'browser'"
    class="domains-table__img-container"
  >
    <div class="domains-table__img-container_content">
      <img :src="data.row.icon" />
    </div>
    <span>{{ data.row.browser }}</span>
  </span>

  <!-- Column: PERCENTAGE -->
  <span v-else-if="data.column.field === 'percentage'">
    <span>{{ data.row.percentage }}%</span>
  </span>

  <!-- Column: ACTIVE USERS -->
  <span v-else-if="data.column.field === 'activeUsers'">
    <span style="display: flex; justify-content: space-around"
      >{{ data.row.activeUsers.toLocaleString() }}
      <GjIcon
        :hidden="data.row.growth === -1"
        name="ArrowUpAlt"
        size="20"
        style="color: #36b37e"
      ></GjIcon>
      <GjIcon
        :hidden="data.row.growth === 1"
        name="ArrowDownAlt"
        size="20"
        style="color: #e34850"
      ></GjIcon>
    </span>
  </span>

  <!-- Column: Common -->
  <span v-else>
    {{ data.formattedRow[data.column.field].toLocaleString() }}
  </span>
</template>

<script>
import { GjIcon } from '@nodus/utilities-front'
import { BTooltip } from 'bootstrap-vue'

export default {
  name: 'RealtimeRow',
  components: {
    BTooltip,
    GjIcon,
  },
  props: {
    data: {
      type: [Array, Object],
    },
  },
  setup() {
    const getDomainFavicon = (pageDomain) =>
      `https://www.google.com/s2/favicons?sz=32&domain=${pageDomain}`

    const updateCleanUrlFormat = (url) => {
      const newUrl = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
      if (newUrl.length > 35) {
        return `${newUrl.slice(0, 20)}...${newUrl.slice(
          newUrl.length - 15,
          newUrl.length
        )}`
      }
      return newUrl
    }

    return {
      getDomainFavicon,
      updateCleanUrlFormat,
    }
  },
}
</script>

<style lang="scss"></style>

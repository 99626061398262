export default [
  {
    path: '/bisko/:organizationId/analytics',
    redirect: '/bisko/:organizationId/analytics/overview'
  },
  {
    path: '/bisko/:organizationId/analytics/overview',
    name: 'overview',
    component: () => import('@/views/overview/Overview'),
    meta: {
      navActiveLink: 'overview',
      pageTitle: 'Overview',
      breadcrumb: [
        {
          text: 'Analytics'
        },
        {
          text: 'Overview',
          active: true
        }
      ]
    }
  },
  {
    path: '/bisko/:organizationId/analytics/overview/:type/detailed',
    name: 'overview-detailed',
    component: () => import('@/views/overview/DetailedView'),
    meta: {
      navActiveLink: 'overview',
      pageTitle: 'Detailed View',
      breadcrumb: [
        {
          text: 'Analytics'
        },
        {
          text: 'Overview',
          to: '/overview'
        },
        {
          text: 'Detailed View',
          active: true
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      const types = {
        urls: "URL's",
        domains: 'Domains',
        sources: 'Sources',
        browsers: 'Browsers'
      }
      to.meta.pageTitle = `Top ${types[to.params.type]}`
      to.meta.breadcrumb[2].text = `Top ${types[to.params.type]}`
      next()
    }
  }
]

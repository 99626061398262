const graphConfig = {
  series: [
    {
      name: 'Page Views',
      data: [],
      labels: {
        formatter: data =>  data.toLocaleString()
      }
    }
  ],
  chart: {
    id: 'analytics',
    type: 'area',
    zoom: {
      enabled: false
    },
    dropShadow: {
      enabled: false,
      color: '#36B37E',
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2
    },
    toolbar: {
      show: false
    }
  },
  colors: ['#1d79f2'],
  fill: {
    type: 'gradient',
    gradient: {
      opacityTo: 0.3,
      stops: [0, 90, 100]
    }
  },
  dataLabels: {
    enabled: false,
    formatter: series => {
      if (series < 1e3) return series
      if (series >= 1e3 && series < 1e6)
        return `${+(series / 1e3).toFixed(1)}K`
      if (series >= 1e6 && series < 1e9)
        return `${+(series / 1e6).toFixed(1)}M`
      if (series >= 1e9 && series < 1e12)
        return `${+(series / 1e9).toFixed(1)}B`
      return series
    }
  },
  stroke: {
    curve: 'smooth'
  },
  title: {
    text: 'Pageviews',
    align: 'left',
    style: {
      fontSize: '20px',
      fontWeight: '700',
      fontFamily: 'Inter',
      color:  '#031C3C'
    }
  },
  grid: {
    borderColor: '#DADFE7',
    strokeDashArray: 8,
    row: {
      colors: ['transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
    padding: {
      left: 25,
    }
  },
  markers: {
    size: 0,
    colors: '#7483A1',
    strokeColors: '#7483A1',
    strokeOpacity: 0.2,
    strokeWidth: 10,
    hover: {
      size: 6
    }
  },
  yaxis: {
    tickAmount: 4,
    labels: {
      show: true,
      formatter: series => series.toLocaleString(),
      type: 'datetime',
      style: {
        colors: '#A3B0C2',
        cssClass: 'analytics-chart__yaxis'
      }
    }
  },
  xaxis: {
    categories: [],
    labels: {
      show: false,
      formatter: val => {
        const formattedDate = new Date(val)
        return `${formattedDate.getDate()}/${formattedDate.getMonth() +
          1}/${formattedDate.getFullYear()}`
      },
      style: {
        colors: '#A3B0C2'
      }
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    crosshairs: {
      show: true,
      width: 1,
      stroke: {
        dashArray: 0,
        color: '#7483A1'
      }
    },
    tooltip: {
      enabled: true,
      formatter: (val, opts) => {
        const label = opts.w.globals.categoryLabels[val-1].split('/')
        const formattedDate = new Date(label[2], label[1] - 1, label[0])
        return formattedDate.toDateString().slice(4)
      }
    }
  },
  noData: {
    text: 'No data has been added yet...'
  }
}

export default graphConfig
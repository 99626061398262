import axiosIns from '@/libs/axios'
import { API_GATEWAY_URL } from '@/utils/constants'
import {
  destructJSONObject,
  formatPropertiesQuery
} from '@nodus/utilities-front'
import browsers from './browsers'
import devices from './devices'
import domains from './domains'
import map from './map'
import pages from './pages'
import sources from './sources'

export default {
  namespaced: true,
  modules: {
    domains,
    pages,
    sources,
    browsers,
    devices,
    map
  },
  state: {
    activeUsers: 0,
    oldActiveUsers: 0,
    realtimeUpdated: false
  },
  getters: {
    activeUsers: (state) => state.activeUsers,
    oldActiveUsers: (state) => state.oldActiveUsers,
    realtimeUpdated: (state) => state.realtimeUpdated
  },
  actions: {
    async getActiveUsers({ commit }, { id, propertyIds }) {
      const properties = formatPropertiesQuery(propertyIds)
      const response = await axiosIns.get(
        `${API_GATEWAY_URL}/api/v1/${id}/realtime/activeuserscount?${properties}`
      )

      commit('SET_ACTIVE_USERS', destructJSONObject(response, 'userCount'))
    }
  },
  mutations: {
    SET_ACTIVE_USERS(state, userCount) {
      state.oldActiveUsers = state.activeUsers
      state.activeUsers = userCount
    },
    SET_REALTIME_UPDATED(state, val) {
      state.realtimeUpdated = val
    }
  }
}

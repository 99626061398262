import axiosIns from '@/libs/axios'
import { API_GATEWAY_URL } from '@/utils/constants'
import {
  destructJSONObject,
  formatPropertiesQuery
} from '@nodus/utilities-front'

export default {
  state: {
    devices: [],
    oldDevices: []
  },
  getters: {
    devices: (state) => state.devices,
    oldDevices: (state) => state.oldDevices
  },
  actions: {
    async getDevices({ commit }, { id, propertyIds }) {
      const properties = formatPropertiesQuery(propertyIds)
      const response = await axiosIns.get(
        `${API_GATEWAY_URL}/api/v1/${id}/realtime/devices?${properties}`
      )

      commit('SET_DEVICES', destructJSONObject(response, 'devices'))
    }
  },
  mutations: {
    SET_DEVICES(state, devices) {
      state.oldDevices = state.devices
      state.devices = devices
    }
  }
}

import urls from './URLs'
import browsers from './browsers'
import devices from './devices'
import domains from './domains'
import general from './general'
import map from './map'
import sources from './sources'

export default {
  namespaced: true,
  modules: {
    domains,
    urls,
    sources,
    browsers,
    devices,
    map,
    general
  }
}

import axiosIns from '@/libs/axios'
import { API_BASE_URL } from '@/utils/constants'
import {
  destructJSONObject,
  formatAnalyticsRequest
} from '@nodus/utilities-front'
import graphConfig from '../graphConfig'

export default {
  state: {
    ...graphConfig
  },
  getters: {
    domainsGraph: (state) => state
  },
  actions: {
    async getDetailedGraphDomains(
      { commit },
      { id, propertyIds, dateFrom, dateTo }
    ) {
      const queryParamString = formatAnalyticsRequest(
        propertyIds,
        dateFrom,
        dateTo
      )
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/analytics/details/graph/domains?${queryParamString}`
      )
      commit('SET_DOMAINS_GRAPH', destructJSONObject(response, 'domainsGraph'))
    }
  },
  mutations: {
    SET_DOMAINS_GRAPH(state, graph) {
      let series = []
      const xaxis = []

      series = graph.map((g) => g.pageViews)
      let xInitialData = []
      xInitialData = graph.map((g) => g.partitionDate)
      xInitialData.forEach((data) => {
        xaxis.push(data.split('T')[0])
      })

      state.series[0].data =
        series.length === 1 ? [...series, ...series] : series
      state.xaxis.categories =
        series.length === 1 ? [...xaxis, ...xaxis] : xaxis
    }
  }
}

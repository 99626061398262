var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "domains__wrapper",
      class: { "domains__wrapper--loading": _vm.isLoading },
    },
    [
      _c("div", { staticClass: "domains__wrapper--padding" }, [
        _c("h5", [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c(
        "section",
        { staticClass: "table__wrapper analytics-table domains-table" },
        [
          _c("Table", {
            attrs: {
              data: _vm.data,
              "type-of-table": "analytics",
              "is-loading": _vm.isLoading,
            },
            scopedSlots: _vm._u([
              {
                key: "row-data",
                fn: function ({ data }) {
                  return [_c("AnalyticsRow", { attrs: { data: data } })]
                },
              },
            ]),
          }),
        ],
        1
      ),
      !_vm.isFooterShown
        ? _c(
            "div",
            {
              staticClass: "domains__wrapper-detailed-view",
              class: { "view__details--disabled": _vm.isOrganizationPlanLite },
              attrs: { id: `more-results-${_vm.type}` },
            },
            [
              _c(
                "b-link",
                {
                  staticClass: "see-more__wrapper",
                  attrs: {
                    to: {
                      name: "overview-detailed",
                      params: {
                        organizationId: _vm.organizationId,
                        type: _vm.type,
                      },
                    },
                  },
                },
                [
                  _c("p", [_vm._v(_vm._s(_vm.$t("moreResults")))]),
                  _c("GjIcon", {
                    staticClass: "mr-50",
                    attrs: { name: "ArrowRight", size: "20" },
                  }),
                  _vm.isOrganizationPlanLite
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "pro-badge",
                          attrs: { small: "", variant: "pro" },
                        },
                        [_vm._v("PRO")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
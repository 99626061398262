// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
      ************ MEDIA QUERY MANAGER ******************

   0 - 576px:         Small devices (landscape phones, 576px and up)
   576px - 768px:     Medium devices (tablets, 768px and up)
   768px - 992px:     Large devices (desktops, 992px and up)
   992px - 1200px:    Big devices (large desktops, 1200px and up)
   1200px - *:        Extra large devices

*/
/*================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.bisko-app__analytics .btn-gradient-primary {
  background-image: linear-gradient(47deg, #1d79f2, #1d79f2);
  color: #fff;
  font-size: 14px;
  line-height: 18px;
}

.bisko-app__analytics .create__button {
  height: 34px;
  margin-left: 10px;
  border-radius: 6px;
}

.bisko-app__analytics ul.dropdown-menu {
  padding: 0px !important;
  border-radius: 4px;
}
.bisko-app__analytics ul.dropdown-menu li:first-child a {
  border-radius: 3px 3px 0 0;
}
.bisko-app__analytics ul.dropdown-menu li:last-child a {
  border-radius: 0 0 3px 3px;
}

.bisko-app__analytics .domains-table .vgt-wrap .vgt-inner-wrap {
  min-height: 330px;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.bisko-app__analytics .domains-table .vgt-wrap .vgt-responsive table tbody tr {
  border-bottom: none;
}

.bisko-app__analytics .domains-table .vgt-wrap .vgt-responsive table tbody tr td {
  padding: 13px 12px;
}

.bisko-app__analytics .loading-class tbody tr {
  border-bottom: none;
}

.bisko-app__analytics .custom-name-header {
  padding-left: 52px !important;
}
.bisko-app__analytics .custom-name-header::before, .bisko-app__analytics .custom-name-header::after {
  left: 38px !important;
}

.bisko-app__analytics .name__wrapper {
  display: flex;
  align-items: center;
  word-break: break-all;
}

.bisko-app__analytics .name-column {
  min-width: 200px !important;
}

.bisko-app__analytics .vs__dropdown-toggle {
  border-color: #e0e5eb !important;
}

.bisko-app__analytics .v-select.vs--single.vs--searchable::after {
  display: none;
}

.bisko-app__analytics .vs__actions .vs__clear {
  margin-right: 0;
}
.bisko-app__analytics .vs__actions .vs__clear[disabled=disabled] {
  display: none;
}

.bisko-app__analytics .v-select .vs__dropdown-toggle .vs__actions > svg {
  margin: auto 9.5px !important;
}

.bisko-app__analytics .v-select .vs__search::-moz-placeholder {
  color: #a3b0c2;
}

.bisko-app__analytics .v-select .vs__search::placeholder {
  color: #a3b0c2;
}

.bisko-app__analytics .dropdown .dropdown-menu .dropdown-item:active {
  background-color: rgba(29, 121, 242, 0.12);
}

.bisko-app__analytics .table__pagination {
  border-top: 1px solid #edeff3;
  font-size: 0.875rem;
  padding: 16px 0;
  position: relative;
  bottom: 0;
  width: 100%;
  margin: 0;
}
.bisko-app__analytics .table__pagination-right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bisko-app__analytics .table__pagination-right ul {
  margin-top: 0 !important;
}
.bisko-app__analytics .table__pagination-right ul li.page-item {
  padding-left: 0 !important;
}
.bisko-app__analytics .table__pagination .specific-page__container {
  display: flex;
  align-items: center;
}
.bisko-app__analytics .table__pagination .specific-page__container span {
  line-height: 18px;
  padding: 4px;
}
.bisko-app__analytics .table__pagination .specific-page__container input {
  width: var(--inputlength, 5ch);
  max-width: 12ch;
  min-width: 40px;
  border-radius: 4px;
  height: 25px;
  font-size: 14px;
  padding: 2px 6px;
  text-align: center;
}
.bisko-app__analytics .table__pagination .pagination__text {
  font-size: 14px !important;
  color: #052d61;
}
.bisko-app__analytics .table__pagination .pagination__text--disabled {
  color: #667c99;
}
.bisko-app__analytics .table__pagination .navigate__button {
  border: none !important;
  padding: 0 0 0 16px;
}
.bisko-app__analytics .table__pagination .navigate__button:hover, .bisko-app__analytics .table__pagination .navigate__button:active, .bisko-app__analytics .table__pagination .navigate__button:focus {
  background: #ffffff !important;
}
.bisko-app__analytics .table__pagination .pagination__icon {
  color: #1d79f2;
}
.bisko-app__analytics .table__pagination .per-page__input {
  border-radius: 4px;
  background: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  width: 40px;
  height: 25px;
  font-size: 14px;
  padding: 2px 6px;
  text-align: center;
  margin-left: 8px;
}

.bisko-app__analytics .header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: self-start;
}

.bisko-app__analytics .form__select {
  font-size: 14px;
  background-color: #ffffff;
}

.bisko-app__analytics .header__wrapper-input {
  max-width: 300px;
  width: 100%;
  font-size: 14px;
}
.bisko-app__analytics .header__wrapper-input .form-control {
  background-color: #ffffff;
}
.bisko-app__analytics .header__wrapper-input .form-control.date.input {
  font-size: 14px;
  height: 35px;
  padding: 8px 35px 8px 12px;
  text-overflow: ellipsis;
}
.bisko-app__analytics .header__wrapper-input .flatpickr-input {
  font-size: 14px;
}

.bisko-app__analytics .calendar__range-input {
  display: flex;
  width: 255px !important;
  height: 34px;
}
.bisko-app__analytics .calendar__range-input .calendar-toggle {
  cursor: pointer;
  position: absolute;
  right: 12px;
  margin: auto 0 2px;
}

.bisko-app__analytics .calendar__range-label {
  white-space: nowrap;
}

.bisko-app__analytics .form__datepicker {
  font-size: 14px;
}

.bisko-app__analytics .col__position-right {
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
}
.bisko-app__analytics .col__position-right span {
  font-size: 14px;
  margin-right: 10px;
  align-self: center;
}

.bisko-app__analytics .col__position-left {
  padding-left: 0;
}

.bisko-app__analytics .domains__wrapper {
  width: 100%;
  padding-left: 20px;
}
.bisko-app__analytics .domains__wrapper h5 {
  color: #031C3C;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 0;
}
.bisko-app__analytics .domains__wrapper p {
  color: #667C99;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
}

.bisko-app__analytics .domains__wrapper--padding {
  padding: 15px 0px 15px 10px;
  display: flex;
  justify-content: space-between;
}

.bisko-app__analytics .map__section {
  background: #ffffff;
  margin: 32px 0;
  border-radius: 8px;
}
.bisko-app__analytics .map__section .map-title {
  padding: 24px 0 24px 8px;
}

.bisko-app__analytics .loading__container {
  margin: auto;
}

.bisko-app__analytics .domains-table__img-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.bisko-app__analytics .domains-table__img-container_content {
  display: flex;
  align-items: center;
  border: 1px solid #e0e5eb;
  border-radius: 4px;
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 10px;
}
.bisko-app__analytics .domains-table__img-container_content img {
  width: 100%;
}
.bisko-app__analytics .domains-table__img-container_content svg {
  fill: #a3b0c2 !important;
}

.bisko-app__analytics .breadcrumb__custom .breadcrumb-item > a {
  color: #c0cfe2;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
